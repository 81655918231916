import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalCargando from "../../../components/Generales/ModalCargando"
import ModalInfoVenta from "../Cobranza/Facturacion/ModalInfoVenta";
import Globales from "utils/Globales";
import { Edit, Close, AddCircle, Search, Clear, Filter9Plus, Add } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactLoading from "react-loading";
import moment from 'moment';
import cookie from 'js-cookie'
import SeleccionarAlumno from "../../Generales/ComponentesEmpresas/SeleccionarAlumno.jsx"
import ModalRealizarCorte from "./ModalRealizarCorte.jsx"
import ModalMovimientoCaja from "./ModalMovimientoCaja.jsx"

var Permisos = localStorage.getItem("Permisos") == null ? { Modulo:[] } : JSON.parse(localStorage.getItem("Permisos"))
if(Permisos.length > 1){
  Permisos = Permisos
}
else{
  let aux = []
  aux.push(Permisos)
  Permisos = aux
}


const validarModo =()=>{
  let User = JSON.parse(localStorage.getItem("Usuario"))
  let permiso = false
  if(User){
    permiso = User.Modo == "true"
  }
  return permiso
}

class Facturacion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      ListaEmpresas: [],
      Folios: [],
      FoliosCancelados:[],
      AlumnoSeleciondado: [],
      VentaSeleccionada: {},
      NombreAlumno: [],
      SumaTotalFacturados: {},
      opcionBuscar: "Alumno",
      FolioVenta: "",
      IDEmpresa: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      NombreEmpresa: "",
      FechaInicio: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      FechaFin: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      idUsuario:null,

      Disabled: false,
      cargando:false,
      mensaje: "",
      mensajePagos: "",
      br: false,
      colorNotificacion: 'success',
      colorTableRow: "white",
      errorFechainicio: false,
      errorFechaFin: false,
      errorFolioVenta: false,
      entroFiltrar: false,
      notificacion: false,


      //totales
      Total: 0,
      TotalEfectivo: 0,
      TotalCheque: 0,
      TotalTransferencia: 0,
      TotalDeposito: 0,
      TotalCredito: 0,
      TotalDebito: 0,
      TotalCancelado:0,
      CuerpoCargando: "Cargando",
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    //this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.obtenerEmpresasActivas();
    this.VentasListadoPorPeriodo();
    
    let IDcookie = JSON.parse(cookie.get('id'));
    this.setState({idUsuario: IDcookie})
  }

  handleCallback = (AlumnoSeleciondado) =>{
    this.setState({AlumnoSeleciondado: AlumnoSeleciondado})
}

//Función para pasar datos de los alumnos desde el modal hacia la vista
  RegresarMensaje = (mensaje) =>{
      this.setState({mensaje: mensaje, colorNotificacion: "success"});
      this.showNotification();

      switch(this.state.opcionBuscar){
        case "FolioVenta":
          if(this.state.FolioVenta !== ""){
            this.obtenerVentaFolioVenta()
          }
          break;
        case "Alumno":
          if(Object.keys(this.state.AlumnoSeleciondado).length == 0){
            //console.log("FILTRAR POR PERIODO")
            this.VentasListadoPorPeriodo()
          }else{
            //console.log("FILTRAR POR ALUMNO")
            this.VentasPorAlumnoRangoFecha()
          }
          break;
      }
  }

  RegresarReporte = (mensaje, reporte) =>{
    this.setState({mensaje: mensaje, colorNotificacion: "success"});
    this.showNotification();
    this.DescargarReporte(reporte, 1)

    // switch(this.state.opcionBuscar){
    //   case "FolioVenta":
    //     if(this.state.FolioVenta !== ""){
    //       this.obtenerVentaFolioVenta()
    //     }
    //     break;
    //   case "Alumno":
    //     if(Object.keys(this.state.AlumnoSeleciondado).length == 0){
    //       //console.log("FILTRAR POR PERIODO")
    //       this.VentasListadoPorPeriodo()
    //     }else{
    //       //console.log("FILTRAR POR ALUMNO")
    //       this.VentasPorAlumnoRangoFecha()
    //     }
    //     break;
    // }
}

  FiltrarMedotoBusqueda(){
    switch(this.state.opcionBuscar){
      case "FolioVenta":
        //console.log("FILTRAR POR FolioVenta")
        this.obtenerVentaFolioVenta()
        break;
      case "Alumno":
        if(Object.keys(this.state.AlumnoSeleciondado).length == 0){
          //console.log("FILTRAR POR PERIODO")
          this.VentasListadoPorPeriodo()
        }else{
          //console.log("FILTRAR POR ALUMNO")
          this.VentasPorAlumnoRangoFecha()
        }
        break;
    }
}

  /* RecuperarMensajeEliminar = (mensaje) =>{
    this.setState({mensaje: mensaje, colorNotificacion: "success"})
    this.showNotification();
  
    let ListaPagosIndex = this.state.ListaPagos.indexOf(this.state.PagoSelecionado);
      this.state.ListaPagos.splice(ListaPagosIndex, 1);
  } */

  //Función para obtener los pagos pendientes de los alumnos
  obtenerEmpresasActivas() {
    this.setState({cargando:true})
    fetch(Globales.Url + 'Facturacion/Facturacion/ListadoEmpresas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify()
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaEmpresas: [], Activo: 1, cargando:false });
          this.showNotification();
        } else { //Filtrado exitoso

          if (data.ListaEmpresas.length > 1) {
            this.setState({ ListaEmpresas: data.ListaEmpresas, Activo: 1, cargando:false });
          }
          else {
            let ListaAuxEmpresas = [];
            ListaAuxEmpresas.push(data.ListaEmpresas);
            //console.log(ListaAux);
            this.setState({ ListaEmpresas: ListaAuxEmpresas, Activo: 1, cargando:false });
          }
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  //Función para obtener las ventas por folio de venta
  obtenerVentaFolioVenta() {
    let valor = 0;

    if (this.state.FolioVenta.trim() === "") {
      this.setState({ errorFolioVenta: true, mensaje: "Proporcione un folio", colorNotificacion: "danger" })
      this.showNotification();
      valor = 1;
    }

    if (valor == 0) {
    this.setState({cargando:true})
    fetch(Globales.Url + 'CorteCaja/CorteCaja/InformacionGeneralVentaFolioRecibo', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        folio: this.state.FolioVenta
      })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Folios: [], Activo: 1, cargando:false });
          this.showNotification();
          this.ObtenerSumaTotalFacturados();
         } else { //Filtrado exitoso

          let AuxVentaFolio = [];
          var venta = data.Ventas
          // console.log(venta)
          this.state.ListaEmpresas.map(emp => {
            //console.log(venta._idEmpresa != emp._id)
            if (JSON.stringify(venta._idEmpresa) == JSON.stringify(emp._id)) {
              // venta["NombreEmpresa"] = emp.RazonSocial
              venta["RazonSocial"] = emp.RazonSocial
            }
          })
          if (validarModo()) {
            let fecha = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            if (!venta.FolioFactura && new Date(venta.Fecha) < fecha) {
              this.setState({ Folios: [], Activo: 1, cargando: false, CheckAll: false });
            } else {
              this.setState({ Folios: [venta], Activo: 1, cargando: false, CheckAll: false });
            }
          } else {
            this.setState({ Folios: [venta], Activo: 1, cargando: false, CheckAll: false });

          }

          this.ObtenerNombreAlumno(venta._idAlumno)
          this.ObtenerSumaTotalFacturados()
          
          
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true})); 
      
    }
  }


  VentasPorAlumnoRangoFecha(){
    let valor1 = 0;
    let valor2 = 0;

    if(this.state.FechaInicio == "" || this.state.FechaFin == ""){
      valor2 = 1
      this.setState({errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos de fechas", colorNotificacion: "danger"})
      this.showNotification();
    }
    else{
      valor2 = 0
      this.setState({errorFechainicio: false, errorFechaFin: false})
    }

    if(valor1 + valor2 == 0){
    this.setState({cargando:true})
    fetch(Globales.Url + 'CorteCaja/CorteCaja/ListaVentasRangoFechaAlumno', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({
        _idAlumno: this.state.AlumnoSeleciondado['_id'], 
        dtInicial: this.state.FechaInicio, 
        dtFinal: this.state.FechaFin, 
        idEmpresa: this.state.IDEmpresa,
        Usuario: JSON.parse(cookie.get('id'))
      })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Folios: [], Activo: 1, cargando:false });
          this.showNotification();
          this.ObtenerSumaTotalFacturados();
         } else { //Filtrado exitoso
          if(data.Ventas.length > 1){

            /*Suma los totales*/
            let suma = 0;
            data.Ventas.map(ventas => {
              suma +=  parseFloat(ventas.Total);
            })

            this.setState({ Folios: data.Ventas, Total: suma, Activo: 1});
          } else{
              let AuxVentaFolio = [];
              AuxVentaFolio.push(data.Ventas);

              let suma = 0;
              suma +=  parseFloat(AuxVentaFolio[0].Total);

              this.setState({ Folios: AuxVentaFolio, Total: suma, Activo: 1});
          }
          this.ObtenerSumaTotalFacturados()
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true})); 
    }
  }

  VentasListadoPorPeriodo(){
    let valor1 = 0;

    if(this.state.FechaInicio == "" || this.state.FechaFin == ""){
      valor1 = 1
      this.setState({errorFechainicio: true, errorFechaFin: true, mensaje: "Complete los campos de fechas", colorNotificacion: "danger"})
      this.showNotification();
    }
    else{
      valor1 = 0
    }

    if(valor1 == 0){
    this.setState({cargando:true})
    fetch(Globales.Url + 'CorteCaja/CorteCaja/ObtenerListaVentasRangoFecha', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ 
        Inicio: this.state.FechaInicio, 
        Fin: this.state.FechaFin, 
        idEmpresa: this.state.IDEmpresa,
        Usuario: JSON.parse(cookie.get('id'))
      })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Folios: [], Activo: 1, cargando:false });
          this.showNotification();
          this.ObtenerSumaTotalFacturados();
         } else { //Filtrado exitoso

          //console.log(data.Folios)
          if(data.Ventas.length > 1)
          {

            /*Suma los totales*/
            let suma = 0;
            data.Ventas.map(ventas => {
              suma +=  parseFloat(ventas.Total);
              ventas.Seleccionado = false
            })

            this.setState({ Folios: data.Ventas, Total: suma, Activo: 1, cargando:false, CheckAll: false });
          }
          else{
              let AuxVentaFolio = [];
              data.Ventas.Seleccionado = false
              AuxVentaFolio.push(data.Ventas);

              let suma = 0;
              suma +=  parseFloat(AuxVentaFolio[0].Total);

              this.setState({ Folios: AuxVentaFolio, Total: suma, Activo: 1, cargando:false, CheckAll: false });
          }
          this.ObtenerSumaTotalFacturados();
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true})); 
    }
  }

  ObtenerNombreAlumno(IdAlumno) {
    this.setState({cargando:true})
    fetch(Globales.Url + 'Facturacion/Facturacion/ObtenerNombreAlumno', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: IdAlumno})
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          //console.log("NO encontro Folio")
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", NombreAlumno: "", Activo: 1, cargando:false });
          this.showNotification();
         } else { //Filtrado exitoso

          //console.log(data.NombreAlumno)
          let Folios = this.state.Folios
          Folios.map(folio => {
            if(JSON.stringify(folio._idAlumno) == JSON.stringify(IdAlumno)){
              folio.NombreAlumno = data.NombreAlumno;
            }
          })

            this.setState({Folios: Folios, cargando:false})
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }
  ObtenerSumaTotalFacturados(){
    var Folios = this.state.Folios
    var FoliosCancelados=[]
    
    var sumaEfectivo =0
    var sumacheque =0
    var sumaTransferencia =0
    var sumaDeposito =0
    var sumaCredito =0
    var sumaCardDebito =0
    var sumaTotal =0
    var sumaCancelado =0
    
    Folios.map( Venta => {
      if(Venta.Cancelada == "true"){
        FoliosCancelados.push(Venta)
        sumaCancelado += parseFloat(Venta.Total);
      }
      else{
        switch(Venta.TipoPago){
          case "Efectivo":
            sumaEfectivo += parseFloat(Venta.Total);
            sumaTotal += parseFloat(Venta.Total);
            break;
          case "Cheque":
            sumacheque += parseFloat(Venta.Total);
            sumaTotal += parseFloat(Venta.Total);
            break;
          case "Transferencia":
            sumaTransferencia += parseFloat(Venta.Total);
            sumaTotal += parseFloat(Venta.Total);
            break;
          case "Depósito":
            sumaDeposito += parseFloat(Venta.Total);
            sumaTotal += parseFloat(Venta.Total);
            break;
          case "Tarjeta_de_Crédito":
            sumaCredito += parseFloat(Venta.Total);
            sumaTotal += parseFloat(Venta.Total);
            break;
          case "Tarjeta_de_Débito":
            sumaCardDebito += parseFloat(Venta.Total);
            sumaTotal += parseFloat(Venta.Total);
            break;
        }
      }
      
    })
    this.setState({
      //pagos cancelados
      FoliosCancelados:FoliosCancelados,
      //totales
      TotalDebito: sumaCardDebito,
      TotalEfectivo: sumaEfectivo,
      TotalCheque: sumacheque,
      TotalTransferencia: sumaTransferencia,
      TotalDeposito: sumaDeposito,
      TotalCredito: sumaCredito,
      Total: sumaTotal,
      TotalCancelado: sumaCancelado,

      cargando:false
    })
  }
  ExportarCorte(){
    var metodoPDF = ""
    if(this.state.opcionBuscar == "FolioVenta"){
      this.setState({ mensaje: "No se permite explortar por folio", colorNotificacion: "danger"});
      this.showNotification();
    }else{
      
      if(Object.keys(this.state.AlumnoSeleciondado).length == 0){
        metodoPDF = "CorteDeCajaPDFRangoFechas"
      }else{
        metodoPDF = "CorteDeCajaPDFRangoFechasAlumno"
      }
    }
    if(metodoPDF != ""){
      this.setState({cargando:true})
      fetch(Globales.Url + 'CorteCaja/CorteCaja/'+metodoPDF, {
        method: 'POST',
        headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ 
          Inicio: this.state.FechaInicio, 
          Fin: this.state.FechaFin, 
          idUsuario: this.state.idUsuario, 
          idAlumno: this.state.AlumnoSeleciondado['_id'],
        })
      }
      ).then(res => res.json())
        .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
            this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Folios: [], Activo: 1, cargando:false });
            this.showNotification();
           } else { //Filtrado exitoso
            //console.log(data.Folios)
            this.DescargarReporte(data.PDF, 0)
          }
        })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true})); 
    }
  }

  DescargarReporte(archivoBase64, int) {
    const url = 'data:application/pdf;base64,'+archivoBase64;
    let nombre;
    if(int == 0){
      nombre = 'Reporte corte de caja'
    }
    else{
      nombre = 'Reporte corte de caja desglose'
    }

    
    const linkSource = `${url}`;
    const downloadLink = document.createElement("a");
    const fileName = `${nombre}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    
    this.setState({
      cargando: false,
      mensaje: "Reporte generado exitosamente", 
      colorNotificacion: "success",
    });
    this.showNotification();
  }
  //Función para abrir el modal de buscar alumnos
  openModalInfoVenta = (Venta) => {
    this.setState({ VentaSeleccionada: Venta, showInfoVenta: true, Titulo: "Información general", op: 1 });
  }

  openModalRealizarCorte = () => {
    this.setState({ showRealizarCorte: true, Titulo: "Desglose de corte de caja", op: 0 });
  }

  openModalMovimientoCaja = () => {
    this.setState({ showMovimientoCaja: true, Titulo: "Movimiento de caja", op: 0 });
  }


  //Función que recupera valores para el modal
  eventoModalInfoVenta = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showInfoVenta: false, op: 0 })
    if (mensaje != 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: "success" })
      this.showNotification();
      this.VentasListadoPorPeriodo()
    }
  }

  eventoModalRealizarCorte = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showRealizarCorte: false, op: 0 })
    if (mensaje != 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: "success" })
      this.showNotification();
    }
  }

  eventoModalMovimientoCaja = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showMovimientoCaja: false, op: 0 })
    if (mensaje != 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: "success" })
      this.showNotification();
    }
  }


  eventoModalcobranza = (ListaAlumnos, colorNotificacion) => {
    //Cambiar valores por default
    this.setState({ showAgregar: false, op: 0 })
    /* if (ListaAlumnos !== 'esc') {
      this.setState({ ListaAlumnos: ListaAlumnos, colorNotificacion: colorNotificacion })
      this.showNotification(); } */
    }

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value, type } = e.target;
    //console.log(e.target);
    if(name == "opciones"){
      this.setState({
          [name]: value,
          opcionBuscar: value,
          FolioVenta: "",
          Folios: [],
          IDEmpresa: {_increment: "0", _machine: "0", _pid: "0", _timestamp: "0"},
          AlumnoSeleciondado: [],
          FechaInicio: new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit'}),
          FechaFin:new Date().toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit', day: '2-digit'}),
          NombreAlumno: "",
          NombreEmpresa: "",
          Total: 0,
          ArrayIdVentas: [],
          ArrayIdVentasVirtual: [],
          TotalSeleccioado: 0,
          TotalEfectivo: 0,
          TotalCheque: 0,
          TotalTransferencia: 0,
          TotalDeposito: 0,
          TotalCredito: 0,
          TotalDebito: 0, 
          CheckAll: false,
          SumaTotalFacturados: {}
        });
    }
    else {
        if(type=="date"){
          this.setState({ [name]: value });
        }else{
          this.setState({ [name]: value });
        }
    }
  }

  ValidarModulo(modulo){
    let valido = false;

    Permisos.map(permiso => {
      if(parseInt(permiso.NumeroModulo) == modulo){
        valido = true
      }
    })

    return valido
  }


  render() {
    const {AlumnoSeleciondado} = this.state;
    const { classes } = this.props;
    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    let DatosAlumno;
    if (AlumnoSeleciondado.length !== 0) {
      
      DatosAlumno =
        <>
        {AlumnoSeleciondado.NombreCompleto} &nbsp;&nbsp;&nbsp;
        </>
    }

    let agregar;
    if (this.state.showInfoVenta == true) {
      agregar =
        <ModalInfoVenta
          Titulo={this.state.Titulo}
          showInfoVenta={this.state.showInfoVenta}
          eventClick={this.eventoModalInfoVenta}
          op={2}
          VentaSeleccionada={this.state.VentaSeleccionada}
          NombreAlumno={this.state.NombreAlumno}
          parentCallback={this.RegresarMensaje}
        />
    }

    if (this.state.showRealizarCorte == true) {
      agregar =
        <ModalRealizarCorte
          Titulo={this.state.Titulo}
          showRealizarCorte={this.state.showRealizarCorte}
          eventClick={this.eventoModalRealizarCorte}
          parentCallback={this.RegresarReporte}
        />
    }

    if (this.state.showMovimientoCaja == true) {
      agregar =
        <ModalMovimientoCaja
          Titulo={this.state.Titulo}
          showMovimientoCaja={this.state.showMovimientoCaja}
          eventClick={this.eventoModalMovimientoCaja}
          parentCallback={this.RegresarMensaje}
        />
    }


    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }
    let Ventas;
    let Aux;
    if(this.state.Folios.length !== 0){
      
      Ventas = 
      this.state.Folios.map((Venta, index) => {
        if(Venta.RazonSocial !== undefined){
          Aux= Venta.RazonSocial
        }
        else if (this.state.NombreEmpresa !== ""){
          Aux = this.state.NombreEmpresa
        }
        else{
          Aux = "Sin Especificar"
        }

        let TipoPagoNombre

        switch(Venta.TipoPago){
          case "Tarjeta_de_Crédito":
            TipoPagoNombre = "Tarjeta de Crédito";
            break;
          case "Tarjeta_de_Débito":
            TipoPagoNombre = "Tarjeta de Débito";
            break;
          case "Cheque":
            TipoPagoNombre = "Cheque";
            break;
          case "Depósito":
            TipoPagoNombre = "Depósito";
            break;
          case "Transferencia":
            TipoPagoNombre = "Transferencia";
            break;
          default:
            TipoPagoNombre = "Efectivo";
            break;
        }

        var StyloRegistro= []
        if(Venta.Cancelada == "true"){
          StyloRegistro ={ cursor: "pointer",color: "red"}
        }else{
          StyloRegistro ={ cursor: "pointer"}
        }

      return(
        <tr key={index} style={StyloRegistro} hover>
          <td style={{verticalAlign: "middle", cursor: "default"}} onClick={() => this.openModalInfoVenta(Venta)}>{Aux}</td>
          <td style={{verticalAlign: "middle", cursor: "default"}} onClick={() => this.openModalInfoVenta(Venta)}>{Venta.FolioRecibo !== null && Venta.FolioRecibo !== undefined ? Venta.FolioRecibo :"Sin Especificar" }</td>
          <td style={{verticalAlign: "middle", cursor: "default"}} onClick={() => this.openModalInfoVenta(Venta)}>{Venta.FolioFactura !== null && Venta.FolioFactura !== undefined ? Venta.FolioFactura.string + " " : "No Facturado"}</td>
          <td style={{verticalAlign: "middle", cursor: "default"}} onClick={() => this.openModalInfoVenta(Venta)}>{moment(Venta.Fecha).format('DD-MM-YYYY h:mm:ss a')}</td>
          <td style={{verticalAlign: "middle", cursor: "default"}} onClick={() => this.openModalInfoVenta(Venta)}>{Venta.NombreAlumno !== null ? Venta.NombreAlumno : "Sin Especificar"}</td>
          <td style={{verticalAlign: "middle", cursor: "default"}} onClick={() => this.openModalInfoVenta(Venta)}>{TipoPagoNombre}</td>
          <td style={{verticalAlign: "middle", cursor: "default"}} onClick={() => this.openModalInfoVenta(Venta)}>{"$"+parseFloat(Venta.Total).toFixed(2)}</td>
        </tr>
      )
      })
    }
    else{
      Ventas = 
      <tr>
        <td colSpan={7} style={{textAlign: "center"}}>NO HAY VENTAS REALIZADAS</td>
      </tr>
    }
    
    let VentasCanceladas;
    let Aux2;
    if(this.state.FoliosCancelados.length !== 0){
      VentasCanceladas = 
      this.state.FoliosCancelados.map((Venta, index) => {
        if(Venta.FolioRecibo !== null){
          if(Venta.RazonSocial !== undefined){
            Aux2 = Venta.RazonSocial
          }
          else if (this.state.NombreEmpresa !== ""){
            Aux2 = this.state.NombreEmpresa
          }
          else{
            Aux2 = "Sin Especificar"
          }


        return(
          <tr key={index} style={{ cursor: "pointer"}} hover>
            <td style={{verticalAlign: "middle", cursor: "default"}} onClick={() => this.openModalInfoVenta(Venta)}>{Aux2}</td>
            <td style={{verticalAlign: "middle", cursor: "default"}} onClick={() => this.openModalInfoVenta(Venta)}>{moment(Venta.Fecha).format('DD-MM-YYYY h:mm:ss a')}</td>
            <td style={{verticalAlign: "middle", cursor: "default"}} onClick={() => this.openModalInfoVenta(Venta)}>{Venta.NombreAlumno !== null ? Venta.NombreAlumno : "Sin Especificar"}</td>
            <td style={{verticalAlign: "middle", cursor: "default", width: "40%",height: "15px"}} onClick={() => this.openModalInfoVenta(Venta)}>
              <div style={{overflow: "hidden", textOverflow: "ellipsis", }}></div>{Venta.MotivosCancelacion}
            </td>
            <td style={{verticalAlign: "middle", cursor: "default"}} onClick={() => this.openModalInfoVenta(Venta)}>{"$"+parseFloat(Venta.Total).toFixed(2)}</td>
          </tr>
        )}/* else{
          return(
            <tr>
              <td colSpan={7} style={{textAlign: "center"}}>NO HAY VENTAS REALIZADAS</td>
            </tr>
          )
        } */
      })
    }
    else{
      VentasCanceladas = 
      <tr>
        <td colSpan={7} style={{textAlign: "center"}}>NO HAY VENTAS REALIZADAS</td>
      </tr>
    }

    // console.log(this.state.Folios);
    //console.log("id Venta", this.state.ArrayIdVentas)
    //console.log("id Ventas Virturales", this.state.ArrayIdVentasVirtual)
    //console.log(this.state.TotalSeleccioado)
    //console.log(this.state.NombreAlumno)
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Pagos</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={10}>
                    <h6>Criterio de búsqueda para la(s) venta(s)</h6>
                  </GridItem>
                  <GridItem id="Empresa de búsqueda" xs={12} sm={12} md={5}>
                    <FormControl size="small">
                      <div>Empresa</div>
                      <Select
                        fullWidth={true}
                        value={this.state.IDEmpresa}
                        onChange={this.handleChange.bind(this)}
                        name="IDEmpresa"
                        //fullWidth={true}
                        variant="outlined"
                        // label="Productos"
                        style={{ width: "18.2vw" }}
                      //error={this.state.errorUsuarios}
                      //success={!this.state.errorUsuarios}
                      >
                        <MenuItem value={this.state.IDEmpresa} disabled={true}>Todas</MenuItem>
                        {this.state.ListaEmpresas.map(Empresa =>
                          <MenuItem value={Empresa._id}>{(Empresa.RazonSocial).toUpperCase()}</MenuItem>
                        )}

                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem id="tipo de búsqueda" xs={12} sm={12} md={6}>
                    <FormControl size="small">
                      <div>Tipo de búsqueda</div>
                      <Select
                        fullWidth={true}
                        value={this.state.opcionBuscar}
                        onChange={this.handleChange.bind(this)}
                        name="opciones"
                        //fullWidth={true}
                        variant="outlined"
                        // label="Productos"
                        style={{ width: "18.2vw" }}
                      //error={this.state.errorUsuarios}
                      //success={!this.state.errorUsuarios}
                      >
                        <MenuItem disabled>Seleccione un tipo</MenuItem>
                        <MenuItem value="FolioVenta">Folio de venta</MenuItem>
                        <MenuItem value="Alumno">Filtro</MenuItem>
                        {/* <MenuItem value="Fechas">Periodo de fechas</MenuItem> */}

                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem id="Parametros de búsqueda" xs={12} sm={12} md={12}>
{/*Opcion FolioVenta*/} <div style={{ marginTop: "16px" }} hidden={this.state.opcionBuscar !== "FolioVenta"}>
                      <TextField
                        id="FolioVenta"
                        style={{ width: "15em" }}
                        variant="outlined"
                        size="small"
                        label="Folio"
                        error={this.state.errorFolioVenta}
                        success={!this.state.errorFolioVenta}
                        InputProps={{
                          onChange: this.handleChange.bind(this),
                          value: this.state.FolioVenta,
                          name: "FolioVenta",
                          id: "FolioVenta",
                        }}
                      /> &nbsp; &nbsp;&nbsp;&nbsp;
                      <Button size="sm" type="submit" color="info" round onClick={() => this.obtenerVentaFolioVenta()}><Search />Buscar</Button>
                    </div>
{/*Opcion Alumno*/}   <div hidden={this.state.opcionBuscar !== "Alumno"}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <SeleccionarAlumno
                            fullWidth={true}
                            inactivo={false}
                            eventClick={this.handleCallback}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <TextField
                            id="FechaInicio"
                            fullWidth={true}
                            style={{ marginTop: "16px" }}
                            variant="outlined"
                            type="date"
                            format="DD-MM-YYYY"
                            size="small"
                            label="Fecha Inicial"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              onChange: this.handleChange.bind(this),
                              value: this.state.FechaInicio,
                              name: "FechaInicio",
                              id: "FechaInicio",
                              error: this.state.errorFechainicio,
                              success: !this.state.errorFechainicio
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <TextField
                            fullWidth={true}
                            id="FechaFin"
                            style={{ marginTop: "16px" }}
                            variant="outlined"
                            size="small"
                            type="date"
                            format="DD-MM-YYYY"
                            label="Fecha Final"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              onChange: this.handleChange.bind(this),
                              value: this.state.FechaFin,
                              name: "FechaFin",
                              id: "FechaFin",
                              error: this.state.errorFechaFin,
                              success: !this.state.errorFechaFin
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2}>
                          <Button style={{ marginTop: "8px" }} size="sm" type="submit" color="info" round onClick={() => this.FiltrarMedotoBusqueda()}><Search />Buscar</Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                    {this.ValidarModulo(13) ? <>
                      <Button hidden={validarModo()} className="pull-right mr-2" color="info" size="sm" type="submit" round onClick={() => this.openModalMovimientoCaja()}>Movimientos de caja</Button>
                      <Button hidden={validarModo()} className="pull-right mr-2" color="info" size="sm" type="submit" round onClick={() => this.openModalRealizarCorte()}>Realizar corte</Button>
                    </> : ""}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Ventas Realizadas</h4>
              </CardHeader>
              <CardBody xs={12} sm={12} md={12}>
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className="table-responsive-xl table-wrapper-scroll-y">
                      <table className="table table-sm table-hover">
                      <thead>           
                        <th className="center">Empresa</th>
                        <th className="center">Folio de venta</th>
                        <th className="center">Folio(s) de factura</th>
                        <th className="center">Fecha</th>
                        <th className="center">Alumno</th>
                        <th className="center">Tipo de pago</th>
                        <th className="center">Cantidad</th>
                      </thead>
                      <tbody>
                        {Ventas}
                      </tbody>
                      </table>
                    </div>
      {/* TOTALES */}{this.ValidarModulo(52) ? <GridContainer style={{ backgroundColor: "#f1f1f1", borderRadius: 3, }} xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={12} >
                        <h4 className="text-center">Totales</h4>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={10} >
                        <h6 className="text-right">Efectivo:</h6>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2} >
                        <h6 className="text-center">{convertirPesos(this.state.TotalEfectivo)}</h6>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={10} >
                        <h6 className="text-right">Cheque:</h6>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2} >
                        <h6 className="text-center">{convertirPesos(this.state.TotalCheque)}</h6>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={10} >
                        <h6 className="text-right">Transferencia:</h6>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2} >
                        <h6 className="text-center">{convertirPesos(this.state.TotalTransferencia)}</h6>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={10} >
                        <h6 className="text-right">Deposito:</h6>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2} >
                        <h6 className="text-center">{convertirPesos(this.state.TotalDeposito)}</h6>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={10} >
                        <h6 className="text-right">Tarjeta de credito:</h6>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2} >
                        <h6 className="text-center">{convertirPesos(this.state.TotalCredito)}</h6>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={10} >
                        <h6 className="text-right">Tarjeta de debito:</h6>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2} >
                        <h6 className="text-center">{convertirPesos(this.state.TotalDebito)}</h6>
                      </GridItem>

                      <GridItem style={{borderTop: '1px solid'}} xs={12} sm={12} md={10} >
                        <h6 className="text-right">Total:</h6>
                      </GridItem>
                      <GridItem style={{borderTop: '1px solid'}} xs={12} sm={12} md={2} >
                        <h6 className="text-center">{convertirPesos(this.state.Total)}</h6>
                      </GridItem>
      {/* TOTALES */}</GridContainer> : ""}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Ventas canceladas</h4>
              </CardHeader>
              <CardBody xs={12} sm={12} md={12}>
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className="table-responsive-xl table-wrapper-scroll-y">
                      <table className="table table-sm table-hover">
                      <thead>           
                        <th className="center">Empresa</th>
                        <th className="center">Fecha de cancelación</th>
                        <th className="center">Alumno</th>
                        <th className="center">Motivos</th>
                        <th className="center">Cantidad</th>
                      </thead>
                      <tbody>
                        {VentasCanceladas}
                      </tbody>
                      </table>
                    </div>
                  </GridItem>
                  {this.ValidarModulo(52) ? <GridItem xs={12} sm={12} md={12}>
                    <GridContainer style={{ backgroundColor: "#f1f1f1", borderRadius: 3, }} xs={12} sm={12} md={12}>
                      <GridItem style={{ borderTop: '1px solid' }} xs={12} sm={12} md={10} >
                        <h6 className="text-right">Total:</h6>
                      </GridItem>
                      <GridItem style={{ borderTop: '1px solid' }} xs={12} sm={12} md={2} >
                        <h6 className="text-center">{convertirPesos(this.state.TotalCancelado)}</h6>
                      </GridItem>
                    </GridContainer>
                  </GridItem> : ""}
                </GridContainer>
              </CardBody>
            </Card><br/><br/>
            <div>
            <Button hidden={validarModo()} size="sm" type="submit" color="info" round className="pull-right" onClick={() => this.ExportarCorte()}>Imprimir movimientos</Button>
              
            </div>
            
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar cobranza"}
            Cuerpo={"¿Está seguro que desea eliminar el cobranza?"}
            show={this.state.show}
          //eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(Facturacion);