import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import Cargando from '../../../components/Generales/ModalCargando';
import ModalCargando from "../../../components/Generales/ModalCargando";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'; 
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, DeleteForever, Clear, Add, Close, Remove, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'
import SeleccionarMaestro from "../../Generales/ComponentesEmpresas/SeleccionarMaestro";
import ModalPreguntaEditable from "../../Generales/ComponentesEmpresas/ModalPreguntaEditable";
import ModalDardeBajaAlumno from "./ModalDardeBajaAlumno";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { number } from "prop-types";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from "@material-ui/core/IconButton";
import SeleccionarHorario from "../../Generales/ComponentesEmpresas/SeleccionarHorario.jsx";

class ModalAsignarGrupo extends Component {

  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      _id: '',
      MaestroSeleccionado: "",
      NombreMaestro: "",
      ListaMaestros: [],
      ListaIdiomas: [],
      ListaNiveles: [],
      ListaGrupos: [],
      ListaGruposTemp: [],
      ListaGruposAlumno: [],
      IdiomaSeleccionado: "",
      NivelSeleccionado: "",
      HoraInicio: "",
      HoraFin: "",
      HoraInicioPM: "am",
      HoraFinPM: "am",
      AlumnoSeleccionado: {IdiomasInscrito:{IdiomaAlumno:[]}},
      GrupoSeleccionado: {},
      ClaseAlumno: "",
      ClaseAlumnoTemp:"",
      TipoCurso: "Tradicional",
      disabled:false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      aviso: "",
      pregunta: 0,
      CuerpoCargando: "Cargando",
      
    }
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick(false);
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    this.ObtenerInformacionAlumno(this.props.AlumnoSeleccionado._id)
    .then(()=>{
      this.ListaGeneralIdiomas();
    })
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  async ObtenerInformacionAlumno(id) {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Alumnos/Alumnos/ObtenerInformacionAlumno', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({_id: id})
    }
    ).then(res => res.json())
        .then(data => {
            // console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
              let aux = [];
              
              if(data.DetalleAlumno.ListaGrupos !== undefined && data.DetalleAlumno.ListaGrupos !== null){
                if(data.DetalleAlumno.ListaGrupos.GrupoAlumno.length > 1){
                  data.DetalleAlumno.ListaGrupos.GrupoAlumno.map(grupo => {
                    if(grupo.Estado == "En_Curso"){
                      aux.push(grupo._idGrupo);
                    }
                  })
                } else{
                  if(data.DetalleAlumno.ListaGrupos.GrupoAlumno.Estado == "En_Curso"){
                    aux.push(data.DetalleAlumno.ListaGrupos.GrupoAlumno._idGrupo);
                  }
                }
              }

              var idiomas = []
              if(data.DetalleAlumno.IdiomasInscrito !== null && data.DetalleAlumno.IdiomasInscrito !== undefined){
                if(data.DetalleAlumno.IdiomasInscrito.IdiomaAlumno.length > 1){
                  idiomas = [...data.DetalleAlumno.IdiomasInscrito.IdiomaAlumno]
                } else{
                  let aux2 =[]
                  aux2.push(data.DetalleAlumno.IdiomasInscrito.IdiomaAlumno);
                  idiomas = aux2
                }
                data.DetalleAlumno.IdiomasInscrito.IdiomaAlumno = idiomas
              }

              //console.log(data.DetalleAlumno)
              this.ObtenerInformacionGruposAlumno(aux);

              this.setState({
                AlumnoSeleccionado: data.DetalleAlumno, 
                ClaseAlumno: data.DetalleAlumno.Clase,
                ClaseAlumnoTemp: data.DetalleAlumno.Clase,
                cargando: false
              })
            }
          })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  async ListaGeneralIdiomas() {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Prospectos/Prospectos/ListaGeneralIdiomas', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify()
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso
                if (data.ListaIdiomas.length > 1) {
                    this.setState({ ListaIdiomas: data.ListaIdiomas, Activo: 1, cargando: false });
                }
                else {
                    let ListaAuxEmpresas = [];
                    ListaAuxEmpresas.push(data.ListaIdiomas);
                    //console.log(ListaAux);
                    this.setState({ ListaIdiomas: ListaAuxEmpresas, Activo: 1, cargando: false });
                }
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async ListaNivelesIdioma(id) {
  // this.setState({cargando: true, ListaNiveles: []});
  await fetch(Globales.Url + 'Prospectos/Prospectos/ListaNivelesIdioma', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({idIdioma: id})
  }
  ).then(res => res.json())
      .then(data => {
          console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", ListaEmpresas: [], Activo: 1 });
              //this.showNotification();
          } else { //Filtrado exitoso
              if (data.ListaNiveles.length > 1 ) {
                  this.setState({ ListaNiveles: data.ListaNiveles, Activo: 1 });
              }
              else {
                  let ListaAux = [];
                  ListaAux.push(data.ListaNiveles);
                  //console.log(ListaAux);
                  this.setState({ ListaNiveles: ListaAux, Activo: 1 });
              }
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  async ListaGGruposIdiomaClase(id, clase, curso) {
    this.setState({ ListaGrupos: [], cargando: true });
    await fetch(Globales.Url + 'Alumnos/Alumnos/ListaGGruposIdiomaClase', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idIdioma: id, Clase: clase, Curso: curso })
    }
    ).then(res => res.json())
      .then(data => {
        // console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ ListaGrupos: [], ListaGruposTemp: [], cargando: false });
        } else { //Filtrado exitoso
          if (data.ListaGrupos.length > 1) {
            this.setState({ ListaGrupos: data.ListaGrupos, ListaGruposTemp: data.ListaGrupos, cargando: false });
          }
          else {
            let Aux = [];
            Aux.push(data.ListaGrupos);
            //console.log(ListaAux);
            this.setState({ ListaGrupos: Aux, ListaGruposTemp: Aux, cargando: false });
          }
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

async ObtenerInformacionGruposAlumno(ids) {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Alumnos/Alumnos/ObtenerInformacionGruposAlumno', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ListaIds: ids})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ ListaGrupos: [], cargando: false });
          } else { //Filtrado exitoso
              if (data.ListaGruposAlumno.length > 1) {
                let aux = [];
                data.ListaGruposAlumno.map(grupo => {
                  if(grupo.Activo == "true"){
                    aux.push(grupo);
                  }
                })

                  this.setState({ ListaGruposAlumno: aux, cargando: false });
              }
              else {
                if(data.ListaGruposAlumno.Activo == "true"){
                  let Aux = [];
                  Aux.push(data.ListaGruposAlumno);
                  //console.log(ListaAux);
                  this.setState({ ListaGruposAlumno: Aux, cargando: false });
                }
              }
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async InscribirAlumnoenIdioma(fecha) {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Alumnos/Alumnos/InscribirAlumnoenIdioma', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({Fecha: fecha, idIdioma: this.state.IdiomaSeleccionado, idAlumno: this.state.AlumnoSeleccionado._id, /* idGrupo: this.state.GrupoSeleccionado._id */})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
            this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", cargando: false });
            this.showNotification();
          } else { //Filtrado exitoso
            //this.setState({ cargando: false });
            //this.showNotification();
            if(this.state.pregunta !== 4){
              this.AsignarGrupoAlumno(this.state.GrupoSeleccionado);
            }
            else{
              this.setState({ mensaje: data.mensaje, colorNotificacion: "success", cargando: false });
              this.showNotification();
              this.ObtenerInformacionAlumno(this.props.AlumnoSeleccionado._id)
            }
            
            //this.ObtenerInformacionAlumno(this.props.AlumnoSeleccionado._id)
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async DardeBajaAlumnoIdioma(motivos) {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Alumnos/Alumnos/DardeBajaAlumnoIdioma', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({idAlumno: this.props.AlumnoSeleccionado._id, idIdioma: this.state.IdiomaSeleccionado, Motivos: motivos})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
            this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", cargando: false });
            this.showNotification();
          } else { //Filtrado exitoso
            this.setState({ mensaje: data.mensaje, colorNotificacion: "success", cargando: false });
            this.showNotification();
            this.state.ListaGruposAlumno.map((grupo, index) => {
              if(grupo.IdIdioma == this.state.IdiomaSeleccionado){
                this.state.ListaGruposAlumno.splice(index, 1)
              }
            })
            this.setState({ListaGruposAlumno: this.state.ListaGruposAlumno})
            this.ObtenerInformacionAlumno(this.props.AlumnoSeleccionado._id)
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async AsignarGrupoAlumno(grupo) {
  let prueba = this.state.ListaGruposAlumno.find(element => JSON.stringify(element._idIdioma) == JSON.stringify(this.state.IdiomaSeleccionado));

  let GrupoAlumno = {
    Calificacion: -1,
    Estado: "En_Curso",
    _idGrupo: grupo._id
  }

  this.setState({cargando: true});
  await fetch(Globales.Url + 'Alumnos/Alumnos/AsignarGrupoAlumno', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({Grupo: GrupoAlumno, _idAlumno: this.props.AlumnoSeleccionado._id, _idGrupoAnterior: prueba !== undefined ? prueba._id : null, tipoConcepto: this.state.TipoCurso })
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
            this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", cargando: false });
            this.showNotification();
          } else { //Filtrado exitoso
            this.setState({ mensaje: data.mensaje, colorNotificacion: "success", cargando: false });
            this.showNotification();
            this.ListaGGruposIdiomaClase(this.state.IdiomaSeleccionado, this.state.ClaseAlumno, this.state.TipoCurso)
            this.ObtenerInformacionAlumno(this.props.AlumnoSeleccionado._id)
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async EliminarAsignacionGrupoAlumno(grupo) {
  let findGrupo
  if(this.state.AlumnoSeleccionado.ListaGrupos.GrupoAlumno.length > 1){
    findGrupo = this.state.AlumnoSeleccionado.ListaGrupos.GrupoAlumno.find(element => JSON.stringify(element._idGrupo) == JSON.stringify(grupo._id))
  }
  else{
    let aux = [];
    aux.push(this.state.AlumnoSeleccionado.ListaGrupos.GrupoAlumno);

    findGrupo = aux.find(element => JSON.stringify(element._idGrupo) == JSON.stringify(grupo._id))
  }
  

  let GrupoAlumno = {
    Calificacion: parseFloat(findGrupo.Calificacion),
    Estado: "Reingreso",
    FechaAsignacion: findGrupo.FechaAsignacion,
    NombreGrupo: findGrupo.NombreGrupo,
    _idGrupo: findGrupo._idGrupo,
    idIdioma: findGrupo.idIdioma,
  }

  this.setState({cargando: true});
  await fetch(Globales.Url + 'Alumnos/Alumnos/EliminarAsignacionGrupoAlumno', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({Grupo: GrupoAlumno, idAlumno: this.state.AlumnoSeleccionado._id})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
            let Index;

            Index = this.state.ListaGruposAlumno.indexOf(grupo);
            this.state.ListaGruposAlumno.splice(Index, 1);

              this.setState({ListaGruposAlumno: this.state.ListaGruposAlumno, mensaje: data.mensaje, colorNotificacion: "success", cargando: false});
              this.showNotification();

              this.ObtenerInformacionAlumno(this.props.AlumnoSeleccionado._id)
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  openModalPregunta = (grupo) => {
    this.setState({
      Titulo: "Asignación de grupos",
      Cuerpo: "Al realizar la asignación del grupo al Alumno se generará las colegiaturas e inscripciónes correspondiente, ¿Está seguro que desea realizar la asignación de grupo?",
      show: true,
      GrupoSeleccionado: grupo,
      FechaOp: 1,
      pregunta: 3
    })
  }

  openModalPreguntaInscribir = (grupo) => {
    this.setState({
      Titulo: "Asignación de grupos",
      Cuerpo: "Al inscribir al alumno a este idioma se generará la inscripción correspondiente y sera tomada en cuenta la fecha seleccionada para los cobros de la inscripción, ¿Seguro que desea continuar?",
      show: true,
      GrupoSeleccionado: grupo,
      FechaOp: 1,
      pregunta: 4
    })
  }
  
  openModalDarDeBaja = () => {
    this.setState({showDardeBajaAlumno: true});
  }

  openModalPreguntaAgregarGrupo = (grupo) => {
    this.setState({
      Titulo: "Asignación de grupos",
      Cuerpo: "Existe un grupo seleccionado para el Idioma especificado, ¿desea reemplazarlo?",
      show: true,
      GrupoSeleccionado: grupo,
      FechaOp: 0,
      pregunta: 1
    })
  }

  openModalPreguntaAgregarGrupo2 = (grupo) => {
    this.setState({
      Titulo: "Asignación de grupos",
      Cuerpo: "Al realizar la asignación del grupo al Alumno se generará las colegiaturas correspondiente, ¿Está seguro que desea realizar la asignación de grupo?",
      show: true,
      GrupoSeleccionado: grupo,
      FechaOp: 0,
      pregunta: 1
    })
  }

  openModalPreguntaAgregarGrupo3 = (grupo) => {
    this.setState({
      Titulo: "Asignación de grupos",
      Cuerpo: "Al eliminar la asignación del grupo se eliminará la colegiatura correspondiente, ¿Seguro que desea eliminar la asignación del grupo?",
      show: true,
      GrupoSeleccionado: grupo,
      FechaOp: 0,
      pregunta: 2
    })
  }

  eventoModalDarDeBaja = (valor) => {
    this.setState({showDardeBajaAlumno: false});
    if(valor !== "esc"){
      this.DardeBajaAlumnoIdioma(valor);
    }
  }

  eventoModalPregunta = (valor) => {
    this.setState({show: false});
    if(valor !== false){
      if(this.state.pregunta == 1){
        this.AsignarGrupoAlumno(this.state.GrupoSeleccionado);
      }  
      else if(this.state.pregunta == 3){
        this.InscribirAlumnoenIdioma(valor);
      }
      else if(this.state.pregunta == 4){
        this.InscribirAlumnoenIdioma(valor);
      }
      else{
        this.EliminarAsignacionGrupoAlumno(this.state.GrupoSeleccionado)
      }
    }
  }

  closeModal() {
    this.props.eventClick(false);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  async handleChange(e) {
    const { name, value } = e.target;
    let aux = [];

    this.setState({ cargando: true });
    switch (name) {
      case "IdiomaSeleccionado":
        this.setState({ [name]: value, ListaNiveles: [], ClaseAlumno: this.state.ClaseAlumnoTemp, TipoCurso: "Tradicional" })

        await this.ListaGGruposIdiomaClase(value, this.state.ClaseAlumnoTemp, this.state.TipoCurso)
          .then(async() => {
            await this.ListaNivelesIdioma(value);
            await this.FiltrarGrupos()
          })
        this.setState({ cargando: false });
        break;
      case "ClaseAlumno":
        this.setState({ [name]: value })

        await this.ListaGGruposIdiomaClase(this.state.IdiomaSeleccionado, value, this.state.TipoCurso)
          .then(() => {
            this.FiltrarGrupos()
          })
        break;
      case "TipoCurso":
        this.setState({ [name]: value })

        await this.ListaGGruposIdiomaClase(this.state.IdiomaSeleccionado, this.state.ClaseAlumno, value)
          .then(() => {
            this.FiltrarGrupos()
          })
        break;
      default:
        this.setState({ [name]: value, cargando: false }, () => {
          this.FiltrarGrupos()
        })
        break;
    }

  }

  //Función para obtener las empresas
  ListaMaestrosBusquedaFiltrada(texto) {
    fetch(Globales.Url + 'Alumnos/Alumnos/ListaMaestrosBusquedaFiltrada', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({ Nombre: texto })
    }
    ).then(res => res.json())
        .then(data => {
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: data.mensaje });
            } else { //Filtrado exitoso
                if (data.ListaMaestros.length > 1) {
                  this.setState({ ListaMaestros: data.ListaMaestros });
                }
                else{
                  let aux = [];
                  aux.push(data.ListaMaestros);
                  this.setState({ListaMaestros: aux})
                }
            }
        })
        .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}


  MaestroSeleccionado = event => {       
    this.props.eventoClick(event.target.value)
  }

  handleChangeAutocomplete(e, newValue){

    //console.log("Autocomplete seleccionado", newValue)

    if(newValue !== undefined && newValue !== null){
    
      this.setState({MaestroSeleccionado: newValue}, () => this.FiltrarGrupos())
    }
  }

  InputChange(e, newInputValue){
    //const { name, value2 } = e.target;
    //console.log(newInputValue)
      //this.ListaMaestrosBusquedaFiltrada(newInputValue);

      this.setState({NombreMaestro: newInputValue});
      this.ListaMaestrosBusquedaFiltrada(newInputValue);
  }

  async FiltrarGrupos(){
    this.setState({ cargando: true });
    if ((this.state.MaestroSeleccionado == "") && (this.state.NivelSeleccionado == "")) {
      //! NO ENTRO FILTRO
      this.setState({ ListaGrupos: this.state.ListaGruposTemp, cargando: false }, () => this.FiltrarHora());

    } else {
      let aux = this.state.ListaGruposTemp;

      //* NO ENTRO FILTRO
      if (this.state.MaestroSeleccionado !== "") {
        aux = aux.filter(grupo =>
          JSON.stringify(grupo._idMaestro) == JSON.stringify(this.state.MaestroSeleccionado._id)
        )
      }

      if (this.state.NivelSeleccionado !== "") {
        aux = aux.filter(grupo =>
          JSON.stringify(grupo._idNivel) == JSON.stringify(this.state.NivelSeleccionado)
        )
      }

      this.setState({ ListaGrupos: aux, cargando: false }, () => this.FiltrarHora());
    }
  }

  FiltrarHora(){

    let aux = [];
    let horainicio = "";
    let horafin = "";
    let HoraInicioPM = "am";
    let HoraFinPM = "am"

    if(this.state.HoraInicio !== "" && this.state.HoraFin !== ""){
      this.state.ListaGrupos.map(grupo => {
        if((grupo.HorarioGrupo.HoraInicio !== null && grupo.HorarioGrupo.HoraInicio !== undefined) && (grupo.HorarioGrupo.HoraFin !== null && grupo.HorarioGrupo.HoraFin !== undefined)){
          horainicio = grupo.HorarioGrupo.HoraInicio
          horafin = grupo.HorarioGrupo.HoraFin
          HoraInicioPM = grupo.HorarioGrupo.FormatoInicio
          HoraFinPM = grupo.HorarioGrupo.FormatoFin

          if((horainicio == this.state.HoraInicio && HoraInicioPM == this.state.HoraInicioPM) && (horafin == this.state.HoraFin && HoraFinPM == this.state.HoraFinPM)){
            aux.push(grupo)
          }
        }
      })

      this.setState({ListaGrupos: aux});
    }
  }

  // FormatearHora(hora,FormatoPM){

  //   console.log("FormatearHora", hora)
  //   // if(hora !== undefined){
  //     var hh = hora.slice(0,3)
  //     hh = parseFloat(hora)
  //     var mm = hora.slice(3,6)
  //   // }

  //   return (parseInt(hh))+":"+mm

  //   // if(FormatoPM == "pm"){
  //   //   return (hh+12)+":"+mm
  //   // }else{
  //   //   if(hh < 9){
  //   //     return "0"+hh+":"+mm
  //   //   }else{
  //   //     return hh+":"+mm
  //   //   }
  //   // }
  // }

  handleMouseDownToken(event) {
    event.preventDefault();
  }

  handleClickEliminarTexto(){
    this.setState({ MaestroSeleccionado: "", NombreMaestro: ""}, () => this.FiltrarGrupos());
  }

  eventoHorario = (HoraInicio, HoraFin, HoraInicioPM, HoraFinPM) => {
    this.setState({
      HoraFinPM: HoraFinPM,
      HoraInicioPM: HoraInicioPM,
      HoraInicio: HoraInicio,
      HoraFin: HoraFin
    }, () => {
      this.FiltrarGrupos()
    })
  }
 
  render() {
    const { classes } = this.props;
    const { ListaGrupos, ListaIdGrupos, ListaGruposTemp } = this.state;
    const prueba = (element) => JSON.stringify(element.IdIdioma) == JSON.stringify(this.state.IdiomaSeleccionado);
    const idiomaGrupo = (element) => JSON.stringify(element._idIdioma) == JSON.stringify(this.state.IdiomaSeleccionado);

    let boton = "";
    let gruposIdioma;
    let idiomaExiste = false
    let PassGrupo = false
    const idIngles = {
      "_increment": "5933375",
      "_machine": "595175",
      "_pid": "2400",
      "_timestamp": "1373551310"
    }

    if (this.state.AlumnoSeleccionado.IdiomasInscrito) {
      idiomaExiste = this.state.AlumnoSeleccionado.IdiomasInscrito.IdiomaAlumno.some(prueba);
      PassGrupo = true
    } else if (this.state.TipoCurso == "CursoVerano" || this.state.TipoCurso == "CursoTOEFL") {
      PassGrupo = true
    }

    console.log("idiomaExiste: ", idiomaExiste);

    let idiomaGrupoExiste = this.state.ListaGruposAlumno.some(idiomaGrupo);

    if(ListaGrupos.length > 0){
      gruposIdioma = 
      ListaGrupos.map(grupo => {
        const prueba2 = (element) => JSON.stringify(element._id) == JSON.stringify(grupo._id);
        if(!this.state.ListaGruposAlumno.some(prueba2)/*  & parseInt(grupo.TotalAlumnos) <= parseInt(grupo.CapacidadAula) */){
          return(
            <tr>
              <td style={{textAlign: "center", verticalAlign: "middle"}}>{grupo.Nombre}</td>
              <td style={{textAlign: "center", verticalAlign: "middle"}}>{grupo.NombreMaestro}</td>
              <td style={{textAlign: "center", verticalAlign: "middle"}}>{moment(grupo.FechaIncioCurso).format("DD-MM-YYYY")}</td>
              <td style={{textAlign: "center", verticalAlign: "middle"}}>{grupo.CapacidadAula}</td>
              <td style={{textAlign: "center", verticalAlign: "middle"}}>{grupo.TotalAlumnos}</td>
              <td><Button data-toggle="tooltip" data-placement="bottom" title="Asignar grupo" color="success" round justIcon size="sm" className="ml-2" onClick={() => PassGrupo ? this.openModalPreguntaAgregarGrupo2(grupo) :
                idiomaExiste ? this.openModalPreguntaAgregarGrupo(grupo) : this.openModalPregunta(grupo)
              }><Add /></Button></td>
            </tr>
            )
        }
        else{
          return(
            <tr>
              <td style={{textAlign: "center", verticalAlign: "middle"}}>{grupo.Nombre}</td>
              <td style={{textAlign: "center", verticalAlign: "middle"}}>{grupo.NombreMaestro}</td>
              <td style={{textAlign: "center", verticalAlign: "middle"}}>{moment(grupo.FechaIncioCurso).format("DD-MM-YYYY")}</td>
              <td style={{textAlign: "center", verticalAlign: "middle"}}>{grupo.CapacidadAula}</td>
              <td style={{textAlign: "center", verticalAlign: "middle"}}>{grupo.TotalAlumnos}</td>
              <td><Button disabled={true} round justIcon size="sm" className="ml-2"><Remove /></Button></td>
            </tr>
            )
        }
      })
    }
    else{
      gruposIdioma = <td colSpan={5} style={{textAlign: "center"}}>NO HAY GRUPOS</td>;
    }

    if(this.state.IdiomaSeleccionado !== ""){
      if(idiomaExiste == true){
        //console.log(true)
        boton = <Button data-toggle="tooltip" data-placement="bottom" title="Eliminar alumno" color="danger" tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.openModalDarDeBaja()}><DeleteForever/>&nbsp;Dar de baja en este idioma</Button>;
      }
      else if(idiomaGrupoExiste){
        //console.log(false)
        // boton = "";
        boton = <Button color="info" tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.openModalPreguntaInscribir()}><Save/>&nbsp;Inscribir en este idioma</Button>;
      }
    }
    else{
      boton = "";
    }
    

    let gruposAlumno;
    if(this.state.ListaGruposAlumno.length > 0){
      gruposAlumno =
      this.state.ListaGruposAlumno.map(grupo => {
        return(
          <tr>
            <td style={{textAlign: "center", verticalAlign: "middle"}}>{grupo.Nombre}</td>
            <td style={{textAlign: "center", verticalAlign: "middle"}}>{grupo.NombreMaestro}</td>
            <td style={{textAlign: "center", verticalAlign: "middle"}}>{moment(grupo.FechaIncioCurso).format("DD-MM-YYYY")}</td>
            <td style={{textAlign: "center", verticalAlign: "middle"}}>{grupo.CapacidadAula}</td>
            <td style={{textAlign: "center", verticalAlign: "middle"}}>{grupo.TotalAlumnos}</td>
            <td><Button data-toggle="tooltip" data-placement="bottom" title="Eliminar asignación al grupo" color="danger" round justIcon size="sm" className="ml-2" onClick={() => this.openModalPreguntaAgregarGrupo3(grupo)}><Close /></Button></td>
          </tr>
          )
      })
    }
    else{
      gruposAlumno = <td colSpan={5} style={{textAlign: "center"}}>NO HAY GRUPOS</td>;
    }

    let agregar;
    if (this.state.cargando) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    if(this.state.showDardeBajaAlumno == true){
      agregar = (
        <ModalDardeBajaAlumno
          Titulo={"Baja de un alumno"}
          showDardeBajaAlumno={this.state.showDardeBajaAlumno}
          eventClick={this.eventoModalDarDeBaja}
          op={1}
        />
      )
    }

    if(this.state.show == true){
      agregar = (
        <ModalPreguntaEditable
          show={this.state.show}
          headerColor={"warning"}
          Titulo={this.state.Titulo}
          Cuerpo={this.state.Cuerpo}
          btnCancelar={"No"}
          btnAceptar={"Si"}
          FechaOp={this.state.FechaOp}
          eventClick={this.eventoModalPregunta}
        />
      )
    }

    return (
      <div>
        <Modal
          isOpen={this.props.showModalAsignarGrupo}
          className="static-modal"
          size="lg"
          toggle={this.closeModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{marginBottom: "10px"}} id="IdiomaLabel">Idioma</InputLabel>
                        <Select
                            labelId="IdiomaLabel"
                            //fullWidth={true}
                            value={this.state.IdiomaSeleccionado}
                            onChange={this.handleChange.bind(this)}
                            name="IdiomaSeleccionado"
                            //fullWidth={true}
                            label="Idioma"
                            style={{ width: "21.9em", marginBottom: "10px" }}
                            //error={this.state.errorIDIdioma}
                            //success={!this.state.errorIDIdioma}
                        >
                            <MenuItem value={""}>Seleccionar el idioma</MenuItem>

                            {this.state.ListaIdiomas.map(idioma => {
                              return(
                                <MenuItem value={idioma._id}>{idioma.Nombre}</MenuItem>
                              )
                            })}
                        </Select>
                </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel id="NivelLabel">Nivel</InputLabel>
                        <Select
                            labelId="NivelLabel"
                            //fullWidth={true}
                            value={this.state.NivelSeleccionado}
                            onChange={this.handleChange.bind(this)}
                            name="NivelSeleccionado"
                            //fullWidth={true}
                            label="Nivel"
                            style={{ width: "22em", marginBottom: "10px" }}
                            //error={this.state.errorIDIdioma}
                            //success={!this.state.errorIDIdioma}
                        >
                            <MenuItem value={""}>Todos los niveles</MenuItem>

                            {this.state.ListaNiveles.map(nivel => {
                              return(
                                <MenuItem value={nivel._id}>{nivel.Nombre}</MenuItem>
                              )
                            })}
                        </Select>
                </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Autocomplete
                      freeSolo
                      id="NombreMaestro"
                      noOptionsText={'No hay opciones'}
                      style={{ maxWidth: "25em", width: "25em", display: "inline-flex" }}
                      disableClearable
                      value={this.state.MaestroSeleccionado}
                      inputValue={this.state.NombreMaestro}
                      onChange={this.handleChangeAutocomplete.bind(this)}
                      onInputChange={this.InputChange.bind(this)}
                      options={this.state.ListaMaestros}
                      getOptionLabel={(option) => option.NombreCompleto}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Nombre del maestro"
                          margin="normal"
                          size="small"
                          style={{marginBottom: "10px", marginTop: 0}}
                          variant="outlined"
                          InputProps={{ 
                            ...params.InputProps, 
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => this.handleClickEliminarTexto()}
                                  onMouseDown={this.handleMouseDownToken}
                                  edge="end"
                                >
                                  <Clear />
                                </IconButton>
                              </InputAdornment>
                            ),
                            type: 'search',
                            name: "NombreMaestro",
                            id: "NombreMaestro",
                            //error: this.state.errorNombre,
                            //success: !this.state.errorNombre
                          }}
                        />
                      )}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{ marginBottom: "10px" }} id="ClaseAlumno">Clase</InputLabel>
                    <Select
                      labelId="ClaseAlumno"
                      //fullWidth={true}
                      value={this.state.ClaseAlumno}
                      onChange={this.handleChange.bind(this)}
                      name="ClaseAlumno"
                      //fullWidth={true}
                      label="Nivel"
                      style={{ width: "22em", marginBottom: "10px" }}
                    //error={this.state.errorIDIdioma}
                    //success={!this.state.errorIDIdioma}
                    >
                      <MenuItem value={"Adulto"}>{"Adulto"}</MenuItem>
                      <MenuItem value={"Adolescente"}>{"Adolescente"}</MenuItem>
                      <MenuItem value={"Niño"}>{"Niño"}</MenuItem>
                      {this.state.IdiomaSeleccionado !== "" ? JSON.stringify(this.state.IdiomaSeleccionado) === JSON.stringify(idIngles) ? <MenuItem value={"Cambridge"}>Cambridge</MenuItem> : "" : ""}

                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                  <FormControl className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{ marginBottom: "10px" }} id="TipoCurso">Curso</InputLabel>
                    <Select
                      labelId="TipoCurso"
                      //fullWidth={true}
                      value={this.state.TipoCurso}
                      onChange={this.handleChange.bind(this)}
                      name="TipoCurso"
                      //fullWidth={true}
                      label="Nivel"
                      style={{ width: "22em", marginBottom: "10px" }}
                    //error={this.state.errorIDIdioma}
                    //success={!this.state.errorIDIdioma}
                    >
                      <MenuItem value={"Tradicional"}>{"Tradicional"}</MenuItem>
                      <MenuItem value={"CursoVerano"}>{"Curso de verano"}</MenuItem>
                      <MenuItem value={"ClaseParticular"}>{"Clase particular"}</MenuItem>
                      {this.state.IdiomaSeleccionado !== "" ? JSON.stringify(this.state.IdiomaSeleccionado) === JSON.stringify(idIngles) ? <MenuItem value={"CursoTOEFL"}>Curso TOEFL</MenuItem> : "" : ""}

                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <SeleccionarHorario
                    op={0}
                    HorarioGrupo={null}
                    SalidaFormato24Hrs={false}
                    AgregarLimpiar={true}
                    eventClick={this.eventoHorario}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>    
          <Card>
          <CardHeader color="info">Lista de grupos</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div className="table-responsive table-wrapper-scroll-y">
                    <table className="table table-sm table-hover ">
                      <thead>
                        <th style={{textAlign: "center"}}>Grupo</th>
                        <th style={{textAlign: "center"}}>Maestro</th>
                        <th style={{textAlign: "center"}}>Inicio de curso</th>
                        <th style={{textAlign: "center"}}>Capacidad</th>
                        <th style={{textAlign: "center"}}>Alumnos inscritos</th>
                        <th></th>
                      </thead>
                      <tbody>
                        {gruposIdioma}
                      </tbody>
                    </table>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
          <CardHeader color="info">Grupos asignado</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div className="table-responsive table-wrapper-scroll-y">
                    <table className="table table-sm table-hover ">
                      <thead>
                        <th style={{textAlign: "center"}}>Grupo</th>
                        <th style={{textAlign: "center"}}>Maestro</th>
                        <th style={{textAlign: "center"}}>Inicio de curso</th>
                        <th style={{textAlign: "center"}}>Capacidad</th>
                        <th style={{textAlign: "center"}}>Alumnos inscritos</th>
                      </thead>
                      <tbody>
                        {gruposAlumno}
                      </tbody>
                    </table>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <GridItem xs={12} sm={12} md={12}>
              <Button tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.props.eventClick(false)}><Clear/>&nbsp;Cerrar</Button>
              {boton}
              
          </GridItem> 
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {agregar}
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalAsignarGrupo);