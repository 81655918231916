import React from "react";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import TextField from "@material-ui/core/TextField";
import CardBody from "components/Card/CardBody.jsx";
import Checkbox from '@material-ui/core/Checkbox';
// material-ui icons
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ModalPregunta from "components/Generales/ModalPregunta";
import ModalCargando from "../../../components/Generales/ModalCargando"
import ModalInfoVenta from "../Cobranza/Facturacion/ModalInfoVenta";
import ModalConsultaAlumnos from "./ModalConsultaAlumnos";
import ModalCalificarGrupo from "./ModalCalificarGrupo";
import ModalSubirNivelGrupo from "./ModalSubirNivelGrupo";
import Globales from "utils/Globales";
import { Edit, People, DoneOutline, GetApp, Close, AssignmentReturned, Archive, PlusOne, DoubleArrow, ArrowUpward, Clear, DoneAll, AssignmentTurnedIn } from "@material-ui/icons";
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactLoading from "react-loading";
import moment from 'moment';
import cookie from 'js-cookie'
import SeleccionarIdioma from "../../Generales/ComponentesEmpresas/SeleccionarIdioma.jsx"
import SeleccionarAula from "../../Generales/ComponentesEmpresas/SeleccionarAula.jsx"
import SeleccionarMaestro from "../../Generales/ComponentesEmpresas/SeleccionarMaestro.jsx"
import Tooltip from '@material-ui/core/Tooltip';
import ModalPreguntaEditable from '../../Generales/ComponentesEmpresas/ModalPreguntaEditable.jsx'


class Facturacion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      ListaEmpresas: [],
      Folios: [],
      FoliosCancelados: [],
      MaestroSeleccionado: { NombreCompleto: "" },
      GrupoSeleccionado: [],
      NombreAlumno: [],
      SumaTotalFacturados: {},
      opcionBuscar: "FolioVenta",
      NombreGrupo: "",
      IDEmpresa: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
      NombreEmpresa: "",
      FechaInicio: "",
      FechaFin: "",
      idUsuario: null,
      NumEstado: 0,
      Disabled: false,
      cargando: false,
      showGrupo: false,
      BoletaGrupo: null,
      GrupoSeleccionado: {},

      mensaje: "",
      mensajePagos: "",
      br: false,
      colorNotificacion: 'success',
      colorTableRow: "white",

      ListaIdiomas:[],
      ListaAulas:[],
      ListaNiveles:[],
      GruposFiltrados: [],
      Clase:"Todos",
      Idioma:"null",
      Aula:"null",
      Nivel: "null",
      HoraInicio:0,
      HoraInicioPM:false,
      HoraFin:12,
      HoraFinPM:true,
      CuerpoCargando: "Cargando",
    };
    //Variable global para indicar el total de registros que se obtendra de la base de datos
    global.entrar = true;
    this.handleChange = this.handleChange.bind(this);
    this.EventoSeleccionarAula = this.EventoSeleccionarAula.bind(this);
    this.EventoSeleccionarIdioma = this.EventoSeleccionarIdioma.bind(this);
    this.eventoModalEliminar = this.eventoModalEliminar.bind(this);
    
  }

  componentDidMount() {
    //console.log(cookie.get('TipoUsuario'))

    let IDcookie = JSON.parse(cookie.get('id'));
    if(cookie.get('TipoUsuario') == "Maestro"){
      this.ListaInformacionGruposMaestros(IDcookie, 0)
    }
    else{
      this.ObtenerGrupos(0)
    }  

    this.setState({ idUsuario: IDcookie })
    this.ListarAulas()
    this.ListarIdiomas()
  }

  handleCallback = (MaestroSeleccionado) => {
    this.setState({ MaestroSeleccionado: MaestroSeleccionado },()=>{
      this.filtrarGrupos()
    })
  }

  //Función para pasar datos de los alumnos desde el modal hacia la vista
  RegresarMensaje = (mensaje) => {
    this.setState({ mensaje: mensaje, colorNotificacion: "success" });
    this.showNotification();
    //this.filtrarGrupos()
  }

  RegresarMensajeYRecargar = (mensaje) => {
    this.setState({ mensaje: mensaje, colorNotificacion: "success" });
    this.showNotification();
    
    let IDcookie = JSON.parse(cookie.get('id'));
    if(cookie.get('TipoUsuario') == "Maestro"){
      this.ListaInformacionGruposMaestros(IDcookie, 0)
    }
    else{
      this.ObtenerGrupos(0)
    } 

  }

  ListarAulas() {
    fetch(Globales.Url + 'Alumnos/Alumnos/ListaGeneralAulas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();
        } else { //Filtrado exitoso

          //console.log('Filtrado exitoso', data.Aulas);
          var ListaAulas = []

          if(data.Aulas.length > 1){
            ListaAulas = data.Aulas;
          }
          else{
            ListaAulas.push(data.Aulas);
          }

          this.setState({ ListaAulas: ListaAulas }, () => {
            if(this.props.op == 1){
              this.setState({
                Aula: JSON.stringify(this.props.cita._idAula),
              })
            }
          })
          //console.log('Filtrado ListaAulas',ListaAulas);
          // console.log("Aula", JSON.stringify(this.props.cita._idAula))

        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  ListarIdiomas() {
    fetch(Globales.Url + 'Idiomas/Idiomas/ListaGeneralIdiomas', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje, });
          this.showNotification();
        } else { //Filtrado exitoso
          var ListaIdiomas = []
          if(data.data.length > 1){
            ListaIdiomas = data.data;
          }
          else{
            ListaIdiomas.push(data.data);
          }
          
          this.setState({ ListaIdiomas: ListaIdiomas })

        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }
  ListarNiveles() {
    fetch(Globales.Url + 'Idiomas/Idiomas/ListaNivelesIdioma', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ 
         idIdioma: JSON.parse(this.state.Idioma) 
      })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) { //Error en la respuesta
          this.setState({  ListaNiveles:[], Nivel: "null" });
          // this.showNotification();
        } else { //Filtrado exitoso
          // this.setState({  ListaNiveles:[], Nivel: "null" });
          var ListaNiveles = []

          if(data.data.length > 1){
            ListaNiveles = data.data;
          }
          else{
            ListaNiveles.push(data.data);
          }
          
          this.setState({ ListaNiveles: ListaNiveles })

        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  async ListaInformacionGruposMaestros(idUsuario, estado) {
    this.setState({cargando:true, Folios: []})
    await fetch(Globales.Url + 'Colaboradores/Colaboradores/ListaInformacionGruposMaestros', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({id: idUsuario, estado: estado})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              //this.showNotification();
            } else { //Filtrado exitoso
              if(estado !== 2){
                let ListaAux;
                if (data.ListaGrupos.length > 1) {
    
                  this.setState({ Folios: data.ListaGrupos, Activo: 1, cargando: false, CheckAll: false });
                }
                else {
                  let AuxVentaFolio = [];
                  AuxVentaFolio.push(data.ListaGrupos);  
      
                  this.setState({ Folios: AuxVentaFolio, Activo: 1, cargando: false, CheckAll: false });
                }
              }
              else{
                let aux = [];
                let ListaAux;
                if (data.ListaGrupos.length > 1) {
    
                  ListaAux = data.ListaGrupos.sort(function(a,b){
                    return new Date(b.FechaTerminoCurso) - new Date(a.FechaTerminoCurso)
                  })
    
                  for(let i = 0; i < 50; i++){
                    aux.push(ListaAux[i])
                  }
      
                  this.setState({ Folios: aux, Activo: 1, cargando: false, CheckAll: false });
                }
                else {
                  let AuxVentaFolio = [];
                  AuxVentaFolio.push(data.ListaGrupos);
      
                  this.setState({ Folios: AuxVentaFolio, Activo: 1, cargando: false, CheckAll: false });
                }
              }
              this.filtrarGrupos() 
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

  ObtenerGrupos(estado) {
    this.setState({cargando:true, Folios: []})
    fetch(Globales.Url + 'Calificaciones/Calificaciones/ListaNombresGruposPorEstado', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ estado: estado })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Folios: [], Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso

          //console.log(data.Grupos)

          if(estado !== 2){
            let ListaAux;
            if (data.Grupos.length > 1) {

              this.setState({ Folios: data.Grupos, Activo: 1, cargando: false, CheckAll: false });
            }
            else {
              let AuxVentaFolio = [];
              AuxVentaFolio.push(data.Grupos);
  
              this.setState({ Folios: AuxVentaFolio, Activo: 1, cargando: false, CheckAll: false });
            }
          }
          else{
            // let aux = [];
            let ListaAux;
            if (data.Grupos.length > 1) {

              ListaAux = data.Grupos.sort(function(a,b){
                return new Date(b.FechaTerminoCurso) - new Date(a.FechaTerminoCurso)
              })

              // for(let i = 0; i < 50; i++){
              //   aux.push(ListaAux[i])
              // }
  
              this.setState({ Folios: ListaAux, Activo: 1, cargando: false, CheckAll: false });
            }
            else {
              let AuxVentaFolio = [];
              AuxVentaFolio.push(data.Grupos);
  
              this.setState({ Folios: AuxVentaFolio, Activo: 1, cargando: false, CheckAll: false });
            }
          }
          this.filtrarGrupos()
        }
      })
      .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
  }

async RegistrarCerrarActa() {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Calificaciones/Calificaciones/RegistrarCerrarActa', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({idGrupo: this.state.GrupoSeleccionado._id, esMaestro: cookie.get('TipoUsuario') == "Maestro" ? true : false})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso
              this.setState({cargando: false, mensaje: data.mensaje, colorNotificacion: "success"});
              this.showNotification();

              let IDcookie = JSON.parse(cookie.get('id'));
              if(cookie.get('TipoUsuario') == "Maestro"){
                this.ListaInformacionGruposMaestros(IDcookie, 0)
              }
              else{
                this.ObtenerGrupos(0)
              } 
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

  filtrarGrupos() {
    let grupos = this.state.Folios;

    var ListaGrupos = []

    // VALIDAR EL NOMBRE DEL GRUPO EN LA LISTA
    grupos.map(grupo => {
      if (this.state.NombreGrupo == "") {
        ListaGrupos.push(grupo)
      } else {
        var Nombre = (grupo.Nombre).toUpperCase()
        var NombreGrupo = (this.state.NombreGrupo).toUpperCase()
        if (Nombre.includes(NombreGrupo)) {
          ListaGrupos.push(grupo)
        }
      }
    })

    // VALIDAR QUE EL MAESTRO SELECCIONADO SEA EL MISMO
    var gruposAux = []
    ListaGrupos.map(grupo => {
      if (this.state.MaestroSeleccionado.NombreCompleto != '') {
        var GrupoMaestro = JSON.stringify(grupo._idMaestro)
        var MaestroSeleccionado = JSON.stringify(this.state.MaestroSeleccionado._id)
        if (GrupoMaestro == MaestroSeleccionado) {
          gruposAux.push(grupo)
        }
      } else {
        gruposAux.push(grupo)
      }
    })
    // ListaGrupos = gruposAux

    if(this.state.Idioma !== "null"){
      ListaGrupos = this.FiltraGruposIdioma(gruposAux)
      ListaGrupos = this.FiltraGruposNivel(ListaGrupos)
      ListaGrupos = this.FiltraGruposAula(ListaGrupos)
      // ListaGrupos = this.FiltraGruposHora(ListaGrupos)
    }
    else{
      ListaGrupos = this.FiltraGruposIdioma(gruposAux)
      ListaGrupos = this.FiltraGruposAula(ListaGrupos)
    }
    
    

    this.setState({GruposFiltrados: ListaGrupos})
  }
  FiltraGruposIdioma(ListaGrupos){
    var gruposAux = []
    if (this.state.Idioma != "null") {
      this.ListarNiveles()

      ListaGrupos.map(grupo => {
        var _idIdioma = JSON.stringify(grupo._idIdioma)
        if (_idIdioma == this.state.Idioma) {
          gruposAux.push(grupo)
        }
      })
      
      return gruposAux
    }else{
      this.setState({
        Nivel:"null",
        ListaNiveles:[],
      })

      return ListaGrupos
    }
  }
  FiltraGruposAula(ListaGrupos){
    var gruposAux = []
    if (this.state.Aula != "null") {
      ListaGrupos.map(grupo => {
        var _idAula = JSON.stringify(grupo._idAula)
        if (_idAula == this.state.Aula) {
          gruposAux.push(grupo)
        }
      })
      
      return gruposAux
    }else{
      return ListaGrupos
    }
  }
  FiltraGruposNivel(ListaGrupos){
    var gruposAux = []
    if (this.state.Nivel != "null") {
      //console.log(this.state.Nivel)
      ListaGrupos.map(grupo => {
        var _idNivel = JSON.stringify(grupo._idNivel)
        if (_idNivel == this.state.Nivel) {
          gruposAux.push(grupo)
        }
      })
      
      return gruposAux
    }else{
      return ListaGrupos
    }
  }
  FiltraGruposHora(ListaGrupos){
    var gruposAux = []
    
    var inicio = parseFloat(this.state.HoraInicio)
      var fin = parseFloat(this.state.HoraFin)

      if (this.state.HoraInicioPM == true) {
        inicio += 12
      }
      if (this.state.HoraFinPM == true) {
        fin += 12
      }

      ListaGrupos.map(grupo => {
        var HoraInicio = parseFloat(grupo.Horario.HoraInicio)
        var HoraFin = parseFloat(grupo.Horario.HoraFin)

        if (grupo.Horario.Formatofin == "pm") {
          HoraFin +=12
        }
        if (grupo.Horario.Formatoinicio == "pm") {
          HoraInicio +=12
        }

        if (HoraInicio >= inicio && HoraFin <= fin) {
          gruposAux.push(grupo)
        }
      })
      
      return gruposAux
  }

  async GenerarBoletasGrupo(idGrupo) {
    this.setState({cargando: true});
    await fetch(Globales.Url + 'Calificaciones/Calificaciones/GenerarBoletasGrupo', {
        method: 'POST',
        headers: {
            Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
        }
        , body: JSON.stringify({idGrupo: idGrupo})
    }
    ).then(res => res.json())
        .then(data => {
            //console.log(data);
            if (data.error) { //Error en la respuesta
                this.setState({ mensaje: "No hay alumnos activos en este grupo", colorNotificacion: "danger", Activo: 1, cargando: false });
                this.showNotification();
            } else { //Filtrado exitoso

                this.DescargarPDF(data.BoletaGrupo, 0);
            }
        })
    .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

async ReporteCalidicacionesGrupo(idGrupo) {
  this.setState({cargando: true});
  await fetch(Globales.Url + 'Calificaciones/Calificaciones/ReporteCalidicacionesGrupo', {
      method: 'POST',
      headers: {
          Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({idGrupo: idGrupo})
  }
  ).then(res => res.json())
      .then(data => {
          //console.log(data);
          if (data.error) { //Error en la respuesta
              this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
              this.showNotification();
          } else { //Filtrado exitoso

              this.DescargarPDF(data.CalificacionesGrupo, 1);
          }
      })
  .catch(err => this.setState({mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true}));
}

DescargarPDF(archivoBase64, int) {
  const url = 'data:application/pdf;base64,' + archivoBase64;
  let nombre;
  if(int == 0){
    nombre = 'BoletasGrupo'
  }
  else{
    nombre = 'ReporteCalificacionesGrupo'
  }
  
  const linkSource = `${url}`;
  const downloadLink = document.createElement("a");
  const fileName = `${nombre}.pdf`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();

  this.setState({
    cargando: false,
    mensaje: "El archivo se generó exitosamente",
    colorNotificacion: "success",
  });
  this.showNotification();
}

  //Función para abrir el modal de buscar alumnos
  openModalBuscarMaestro = () => {
    this.setState({ showAgregar: true, Titulo: "Buscar Alumnos", op: 1 });
  }

  openModalConsultaAlumnos(idGrupo){
    this.setState({_id: idGrupo, showConsultaAlumno: true, Titulo: "Alumnos inscritos", op: 0});
  }

  openModalCalificarGrupo(Grupo, op){
    this.setState({GrupoSeleccionado: Grupo, showCalificarGrupo: true, Titulo: "Calificar alumnos", op: op});
  }

  openModalSubirNivelGrupo(Grupo){
    this.setState({GrupoSeleccionado: Grupo, showSubirNivelGrupo: true, Titulo: "Subir nivel grupo", op: 1});
  }

  openModalPregunta(Grupo){
    this.setState({GrupoSeleccionado: Grupo, showPregunta: true});
  }

  eventoPregunta = (valor) => {
    //Cambiar valores por default
    this.setState({ showPregunta: false, op: 0 })
    if (valor) {
        this.RegistrarCerrarActa();
    }
}

  eventoConsultaAlumno = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showConsultaAlumno: false, op: 0 })
    if (mensaje != 'esc') {
        this.setState({ mensaje: mensaje, colorNotificacion: "success" })
        this.showNotification();
    }
}

  eventoCalificarGrupo = (mensaje) => {
    //Cambiar valores por default
    this.setState({ showCalificarGrupo: false, op: 0 })
    if (mensaje != 'esc') {
        this.setState({ mensaje: mensaje, colorNotificacion: "success" })
        this.showNotification();
    }
}

eventoSubirNivelGrupo = (mensaje) => {
  //Cambiar valores por default
  this.setState({ showSubirNivelGrupo: false, op: 0 })
  if (mensaje != 'esc') {
      this.setState({ mensaje: mensaje, colorNotificacion: "success" })
      this.showNotification();
  }
}

  //Función que habilita la notificación
  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false, notificacion: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  //Función para obtener el texto de los inputs
  handleChange(e) {
    const { name, value, type } = e.target;
    //console.log(e.target);
    if (type == "number") {
      if( value == ""){
        this.setState({ [name]: 0 });
      }else{
        if ((parseFloat(value) < 0) || (parseFloat(value) > 12)) {
          this.setState({ mensaje: "Seleccione una hora valida", colorNotificacion: "danger" });
          this.showNotification();
        } else {
          this.setState({ [name]: value }, () => { this.filtrarGrupos() });
        }
      }

    }else{
      this.setState({ [name]: value }, () => { this.filtrarGrupos() });
    }

    if(name == "NumEstado"){
      this.setState({ [name]: value });

      let IDcookie = JSON.parse(cookie.get('id'));
      if(cookie.get('TipoUsuario') == "Maestro"){
        this.ListaInformacionGruposMaestros(IDcookie, value)
      }
      else{
        this.ObtenerGrupos(value)
      } 
    }
  }

  EventoSeleccionarIdioma(idIdioma) {
    this.setState({ Idioma: idIdioma });
  }
  EventoSeleccionarAula(Aula) {
    this.setState({ Aula: Aula });
  }

  eventoClickCerrar = (mensaje) =>{
    this.props.eventClick(mensaje)
  }
  eventoModalEliminar = (Exito) => {
    this.setState({
      show: false,
    });
    if (Exito) {
      this.EliminarGrupos(this.state.GrupoSeleccionado._id)
    }
  }
  

  render() {
    const { _id, GrupoSeleccionado } = this.state;

    let agregar;

    if (this.state.showConsultaAlumno == true) {
      agregar =
        <ModalConsultaAlumnos
          Titulo={this.state.Titulo}
          showConsultaAlumno={this.state.showConsultaAlumno}
          eventClick={this.eventoConsultaAlumno}
          op={this.state.op}
          parentCallback={this.RegresarMensaje}
          idGrupo={_id}
        />
    }

    if (this.state.showCalificarGrupo == true) {
      agregar =
        <ModalCalificarGrupo
          Titulo={this.state.Titulo}
          showCalificarGrupo={this.state.showCalificarGrupo}
          eventClick={this.eventoCalificarGrupo}
          op={this.state.op}
          parentCallback={this.RegresarMensaje}
          GrupoSeleccionado={GrupoSeleccionado}
        />
    }

    if (this.state.showSubirNivelGrupo == true) {
      agregar =
        <ModalSubirNivelGrupo
          Titulo={this.state.Titulo}
          showSubirNivelGrupo={this.state.showSubirNivelGrupo}
          eventClick={this.eventoSubirNivelGrupo}
          op={this.state.op}
          parentCallback={this.RegresarMensajeYRecargar}
          GrupoSeleccionado={GrupoSeleccionado}
        />
    }

    if(this.state.showPregunta == true){
      agregar = (
        <ModalPreguntaEditable
          show={this.state.showPregunta}
          headerColor={'warning'}
          Titulo={'Cerrar acta'}
          Cuerpo={cookie.get('TipoUsuario') == "Maestro" ? 'Al cerrar el acta no podrá modificar nuevamente las calificaciones, ¿Desea continuar?' : '¿Está seguro de cerrar el acta?'}
          btnCancelar={'Cancelar'}
          btnAceptar={'Aceptar'}
          eventClick={this.eventoPregunta}
        />
      )
    }


    if (this.state.cargando == true) {
      agregar = (
        <ModalCargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      );
    }

    const { classes } = this.props;
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
    const convertirPesos = (number) => {
      return new Intl.NumberFormat("ES-MX", {
        style: 'currency',
        currency: 'MXN'
      }).format(number);
    }

    let grupos;
    let boton;
    let icono;
    if(this.state.GruposFiltrados.length > 0){
      if(this.state.NumEstado == 0){
        grupos = (
          this.state.GruposFiltrados.map((grupo, index) => {

            if(grupo.UltimoNivel == "true"){
              boton = <Button data-toggle="tooltip" data-placement="bottom" title="Cerrar acta" color="warning" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.openModalPregunta(grupo)}><Archive /></Button>;
              icono = <Archive />;
            }
            else{
              boton = <Button data-toggle="tooltip" data-placement="bottom" title="Subir de nivel" color="success" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.openModalSubirNivelGrupo(grupo)}><ArrowUpward /></Button>;
              icono = <ArrowUpward />;
            }

            let Modalidad = "";
            if(grupo.Modalidad == "En_linea"){
              Modalidad = "En linea"
            }
            else{
              Modalidad = grupo.Modalidad;
            }

            return (
              <tr style={{cursor: "default"}} hover>
                <td style={{verticalAlign: "middle"}}>{grupo.Nombre}</td>
                <td style={{verticalAlign: "middle"}}>{Modalidad}</td>
                <td style={{verticalAlign: "middle"}}>{grupo.NombreMaestro !== undefined && grupo.NombreMaestro !== null ? grupo.NombreMaestro : "No asignado"}</td>
                <td style={{verticalAlign: "middle", textAlign: "center"}}>{moment(grupo.FechaIncioCurso).format("DD-MM-YYYY")}</td>
                <td style={{verticalAlign: "middle", textAlign: "center"}}>{grupo.TotalAlumnos}</td>
                <td>
                  {parseInt(grupo.TotalAlumnos) !== 0 && JSON.stringify(grupo._idMaestro) !== JSON.stringify(idVacio) ? 
                  <>
                    {boton}
                    <Button data-toggle="tooltip" data-placement="bottom" title="Calificar" color="success" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.openModalCalificarGrupo(grupo, 0)}><AssignmentTurnedIn /></Button>
                    <Button data-toggle="tooltip" data-placement="bottom" title="Descargar calificaciones" color="info" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.ReporteCalidicacionesGrupo(grupo._id)}><AssignmentReturned /></Button>
                    <Button data-toggle="tooltip" data-placement="bottom" title="Descargar boletas" color="info" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.GenerarBoletasGrupo(grupo._id)}><GetApp /></Button>
                    <Button data-toggle="tooltip" data-placement="bottom" title="Consultar alumnos" color="info" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.openModalConsultaAlumnos(grupo._id)}><People /></Button>
                  </>:
                  <>
                    <span data-toggle="tooltip" data-placement="bottom" title="No hay alumnos o maestro asignado en este grupo">
                      <Button disabled round justIcon size="sm" className="mr-2 pull-right">{icono}</Button>
                    </span>
                    <span data-toggle="tooltip" data-placement="bottom" title="No hay alumnos o maestro asignado en este grupo">
                      <Button disabled round justIcon size="sm" className="mr-2 pull-right"><AssignmentTurnedIn /></Button>
                    </span>
                    <span data-toggle="tooltip" data-placement="bottom" title="No hay alumnos o maestro asignado en este grupo">
                      <Button disabled round justIcon size="sm" className="mr-2 pull-right"><AssignmentReturned /></Button>
                    </span>
                    <span data-toggle="tooltip" data-placement="bottom" title="No hay alumnos o maestro asignado en este grupo">
                      <Button disabled round justIcon size="sm" className="mr-2 pull-right"><GetApp /></Button>
                    </span>
                    <span data-toggle="tooltip" data-placement="bottom" title="No hay alumnos o maestro asignado en este grupo">
                      <Button disabled round justIcon size="sm" className="mr-2 pull-right"><People /></Button>
                    </span>
                  </>
                  }
                </td>
              </tr>
            )
          })
        )
      }
      else{
        grupos =
        this.state.GruposFiltrados.map((grupo, index) => {

          let Modalidad = "";
            if(grupo.Modalidad == "En_linea"){
              Modalidad = "En linea"
            }
            else{
              Modalidad = grupo.Modalidad;
            }

          return (
            <tr style={{cursor: "default"}} hover>
              <td onClick={() => this.openModalCalificarGrupo(grupo, 1)} style={{verticalAlign: "middle"}}>{grupo.Nombre}</td>
              <td style={{verticalAlign: "middle"}}>{Modalidad}</td>
              <td onClick={() => this.openModalCalificarGrupo(grupo, 1)} style={{verticalAlign: "middle"}}>{grupo.NombreMaestro !== undefined && grupo.NombreMaestro !== null ? grupo.NombreMaestro : "No asignado"}</td>
              <td onClick={() => this.openModalCalificarGrupo(grupo, 1)} style={{verticalAlign: "middle", textAlign: "center"}}>{moment(grupo.FechaIncioCurso).format("DD-MM-YYYY")}</td>
              {/* <td onClick={() => this.openModalCalificarGrupo(grupo, 1)} style={{verticalAlign: "middle", textAlign: "center"}}>{grupo.TotalAlumnos}</td> */}
              <td>
                <>
                  <Button data-toggle="tooltip" data-placement="bottom" title="Calificar" color="success" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.openModalCalificarGrupo(grupo, 1)}><AssignmentTurnedIn /></Button>
                  {/* <Button data-toggle="tooltip" data-placement="bottom" title="Descargar calificaciones" color="info" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.ReporteCalidicacionesGrupo(grupo._id)}><AssignmentReturned /></Button> */}
                  {/* <Button data-toggle="tooltip" data-placement="bottom" title="Descargar boletas" color="info" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.GenerarBoletasGrupo(grupo._id)}><GetApp /></Button> */}
                  <Button data-toggle="tooltip" data-placement="bottom" title="Consultar alumnos" color="info" round justIcon size="sm" className="mr-2 pull-right" onClick={() => this.openModalConsultaAlumnos(grupo._id)}><People /></Button>
                </>
              </td>
            </tr>
          )
        })
      }
    }
    else{
      grupos = (
        <tr><td colSpan="5"className="text-center"><h5>No hay grupos disponibles</h5></td></tr>
      )
    }

    let autocompleteAdmin;
    if(cookie.get('TipoUsuario') !== "Maestro"){
      autocompleteAdmin = (
        <GridItem style={{ marginTop: "13px" }} id="Parametros de búsqueda" xs={12} sm={12} md={5}>
          <SeleccionarMaestro
            eventClick={this.handleCallback}
          />
        </GridItem>
      );
    }

    //console.log(this.state.MaestroSeleccionado)
    // console.log("GruposFiltrados ", this.state.GruposFiltrados)
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Grupos</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                <GridItem style={{ marginTop: "2em" }} id="Parametros de búsqueda" xs={12} sm={12} md={5}>
                    {/*Opcion FolioVenta*/}
                    <TextField
                      id="FolioVenta"
                      style={{ width: "18.2vw" }}
                      variant="outlined"
                      size="small"
                      label="Nombre del grupo"
                      error={this.state.errorFolioVenta}
                      success={!this.state.errorFolioVenta}
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.NombreGrupo,
                        name: "NombreGrupo",
                        id: "NombreGrupo",
                      }}
                    />
                  </GridItem>
                  <GridItem style={{ marginTop: "2em" }} id="Parametros de búsqueda" xs={12} sm={12} md={5}>
                  <FormControl
                      fullWidth
                      size="small"
                      style={{ width: "18.2vw" }}
                      >
                      <InputLabel style={{paddingLeft: "20px", top: "-10px"}} id="label1">Estado</InputLabel>
                      <Select
                          labelId="label1"
                          value={this.state.NumEstado}
                          onChange={this.handleChange.bind(this)}  
                          size="small"  
                          variant="outlined"     
                          name="NumEstado"  
                          label="Estado"
                      >
                        <MenuItem disabled>Seleccione estado</MenuItem>
                      <MenuItem value={0}>En curso</MenuItem> 
                      <MenuItem value={2}>Finalizado</MenuItem> 
                      </Select>
                    </FormControl>
                  </GridItem>
                  {autocompleteAdmin}
                  <GridItem style={{ marginTop: "2em" }} id="tipo de búsqueda" xs={12} sm={12} md={5}>
                    <FormControl
                        fullWidth
                        size="small"
                        style={{ width: "18.2vw" }}
                        >
                        <InputLabel style={{paddingLeft: "20px", top: "-10px"}} id="label2">Idioma</InputLabel>
                        <Select
                            labelId="label2"
                            label="Idioma"
                            value={this.state.Idioma}
                            onChange={this.handleChange.bind(this)}  
                            size="small"  
                            variant="outlined"     
                            name="Idioma"  
                        >
                        <MenuItem value={"null"}>Todos los idiomas</MenuItem> 
                        {this.state.ListaIdiomas.length == 0 ? null :
                            this.state.ListaIdiomas.map((idioma) => {
                                return (<MenuItem value={JSON.stringify(idioma._id)} >{idioma.Nombre}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem style={{ marginTop: "2em" }} id="tipo de búsqueda" xs={12} sm={12} md={5}>
                    <FormControl
                        fullWidth
                        size="small"
                        style={{ width: "18.2vw" }}
                        >
                        <InputLabel style={{paddingLeft: "20px", top: "-10px"}} id="label3">Nivel</InputLabel>
                        <Select
                            labelId="label3"
                            label="Nivel"
                            value={this.state.Nivel}
                            onChange={this.handleChange.bind(this)}   
                            variant="outlined"    
                            name="Nivel"  
                        >
                        {this.state.ListaNiveles.length == 0 ?
                          <MenuItem value={"null"}>Seleccione un idioma</MenuItem>
                          :
                          <MenuItem value={"null"}>Todos los niveles</MenuItem>
                        }
                        {this.state.ListaNiveles.length == 0 ? null :
                            this.state.ListaNiveles.map((idioma) => {
                                return (<MenuItem value={JSON.stringify(idioma._id)} >{idioma.Nombre}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem style={{ marginTop: "2em" }} id="tipo de búsqueda" xs={12} sm={12} md={5}>
                    <FormControl
                        fullWidth
                        size="small"
                        style={{ width: "18.2vw" }}
                        >
                        <InputLabel style={{paddingLeft: "20px", top: "-10px"}} id="label4">Aula</InputLabel>
                        <Select
                            labelId="label4"
                            label="Aula"
                            value={this.state.Aula}
                            onChange={this.handleChange.bind(this)}   
                            variant="outlined"    
                            name="Aula"  
                        >
                          <MenuItem value={"null"}>Todas las aulas</MenuItem> 
                        {this.state.ListaAulas.length == 0 ? null :
                            this.state.ListaAulas.map((aula) => {
                                return (<MenuItem value={JSON.stringify(aula._id)} >{aula.Nombre}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" text>
                <h4 className={classes.cardTitle}>Lista de grupos</h4>
              </CardHeader>
              <CardBody xs={12} sm={12} md={12}>
                <GridContainer xs={12} sm={12} md={12}>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className="table-responsive-xl table-wrapper-scroll-y">
                      <table className="table table-sm table-hover">
                        <thead>
                          <th>Grupo</th>
                          <th>Modalidad</th>
                          <th>Maestro</th>
                          <th style={{textAlign: "center"}}>Fecha de inicio</th>
                          {this.state.NumEstado == 0 ? <th style={{textAlign: "center"}}>Total de alumnos</th> : null}
                          <th></th>
                        </thead>
                        <tbody>
                            {grupos}
                        </tbody>
                      </table>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div id="">
          <ModalPregunta
            Titulo={"Eliminar grupo"}
            Cuerpo={"¿Está seguro que desea eliminar grupo?"}
            show={this.state.show}
          eventClick={this.eventoModalEliminar}
          />
          {agregar}
        </div>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(Facturacion);