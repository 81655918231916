import React, { Component } from "react";
import { Modal } from "reactstrap";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from "../../../../components/Generales/ModalCargando";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Close} from "@material-ui/icons";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { convertirPesos } from "../../../../components/Generales/Utileria";
import TextField from "@material-ui/core/TextField";

class ModalProductos extends Component {
  constructor(props, context) {
    super(props, context);
    this.escFunction = this.escFunction.bind(this);
    this.state = {
      _idIdioma: "",
      ListaIdiomas: [],
      ListaUnidades: [],
      ListaProveedores: [],
      _idTipoProducto: "",
      ListaTipoProductos: [],
      _id: "",
      _idProveedor: "",
      Codigo: "",
      Costo: "",
      Precio: "",
      CostoOrg: "",
      PrecioOrg: "",
      Descripcion: "",
      Existencia: "",
      NombreUnidad: "",
      Control: false,
      disabled: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton: false,
      ErrorPrecio: false,
      ErrorCosto: false,
      ErrorProveedor: false,
      ErrorCodigo: false,
      ErrorDescripcion: false,
      ErrorTipo: false,
      ErrorIdioma: false,
      ErrorExistencia: false,
      ErrorUnidad: false,
      IdNormal: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" },
    };
    this.RegistrarProductos = this.RegistrarProductos.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick("esc", "esc");
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    this.ListaGeneralProveedores();
    const { Idiomas, TipoProducto } = this.props;
    this.setState({
      ListaIdiomas: Idiomas,
      ListaTipoProductos: TipoProducto,
    });

    //Verificar si es editar
    if (this.props.op == 1) {
      const {_id} = this.props.InformacionProducto
      this.InformacionProducto(_id)
    }
  }

  async InformacionProducto(_idProducto) {
    this.setState({ cargando: true });
    await fetch(Globales.Url + "Catalogos/Productos/ConsultarProducto", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body:JSON.stringify({
        _idProducto
      })
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data)
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.showNotification();
        } else {
          const {
            AplicaExistencia,
            Codigo,
            Costo,
            Descripcion,
            Existencia,
            IdIdioma,
            IdProveedor,
            Precio,
            Tipo,
            _id,
            Unidades
          } = data.ListaProductos
          //  console.log(AplicaExistencia == "true" ? true : false)
          let listaUnidades =[]
          if(Unidades){
            if(Unidades.Unidad.length > 1){
              Unidades.Unidad.forEach(unidad => {
                listaUnidades.push(unidad)
              })
            }else{
              listaUnidades = [Unidades.Unidad]
            }
          }
          this.setState({
            _idTipoProducto:Tipo,
            _id,
            _idProveedor:JSON.stringify(IdProveedor) || '',
            Codigo,
            Costo,
            Precio,
            CostoOrg:Costo,
            PrecioOrg:Precio,
            Descripcion,
            Existencia,
            Control: AplicaExistencia == "true" ? true : false,
            _idIdioma:JSON.stringify(IdIdioma) || "",
            ListaUnidades: listaUnidades,
            cargando: false,
          });
          /* if(Tipo === 'Libro'){
            this.ListarUnidades(_id)
          } */
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  /* async ListarUnidades(IdLibro){
    this.setState({ cargando: true });
    await fetch(Globales.Url + "Catalogos/Unidades/ListarUnidadesPorLibro", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body:JSON.stringify({
        IdLibro
      })
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.showNotification();
        } else {
          //console.log(data)
        }
      })
      .catch((err) => console.error("El error es", err));
  } */

  async ListaGeneralProveedores() {
    this.setState({ cargando: this.state.entroEliminar ? false : true });
    await fetch(Globales.Url + "Catalogos/Proveedores/ListarProveedores", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          //Error en la respuesta
          this.setState({
            mensaje: data.mensaje,
            colorNotificacion: "danger",
            Activo: 1,
            cargando: false,
            entroEliminar: false,
          });
          this.showNotification();
        } else {
          if (data.ListadeProveedores.Proveedor.length > 1) {
            this.setState({
              ListaProveedores: data.ListadeProveedores.Proveedor,
            });
          } else {
            this.setState({
              ListaProveedores: data.ListadeProveedores.Proveedor,
            });
          }
        }
      })
      .catch((err) => console.error("El error es", err));
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  async ValidarCodigo() {
    this.setState({ cargando: true });
    await fetch(Globales.Url + "Catalogos/Productos/ValidarCodigoProducto", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: Globales.token,
      },
      body: JSON.stringify({
        Codigo: this.state.Codigo,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data)
        if (data.error) {
          this.setState({
            colorNotificacion: "danger",
            mensaje: data.mensaje,
            cargando:false
          });
          this.showNotification();
        } else {
          if(data.Validar || this.props.op === 1){
            this.RegistrarProductos()
          }else{
            this.setState({
              colorNotificacion: "danger",
              mensaje: data.mensaje,
              ErrorCodigo:true,
              cargando:false
            });
            this.showNotification();
          }
        }
        this.setState({
          cargando:false
        })
      })
      .catch((err) => console.error(err));
  }

 async ValidarDatos(){
    if(this.state._idIdioma === ''){
      this.setState({
        ErrorIdioma:'true'
      })
      return
    }
    if(this.state._idTipoProducto === ''){
      this.setState({
        ErrorTipo:'true'
      })
      return
    }
    if(this.state.Codigo === ''){
      this.setState({
        ErrorCodigo:'true'
      })
      return
    }
    if(this.state.Descripcion === ''){
      this.setState({
        ErrorDescripcion:'true'
      })
      return
    }
    if(this.state.Existencia === '' || this.state.Existencia < 0){
      this.setState({
        ErrorExistencia:'true'
      })
      return
    }
    if(this.state.PrecioOrg <= 0){
      this.setState({
        ErrorPrecio:'true'
      })
      return
    }
    if(this.state.CostoOrg <= 0){
      this.setState({
        ErrorCosto:'true'
      })
      return
    }
    if(this.state._idProveedor === ''){
      this.setState({
        ErrorProveedor:'true'
      })
      return
    }

    if(this.props.op == 1){
      await this.ValidarCodigo()
    }
    else{
      this.RegistrarProductos()
    }
    
  }

  RegistrarProductos() {
    this.setState({
      disabled: true,
      MostrarModal: true,
      DesactivarBoton: true,
    });
    if (this.props.op == 1) {
      const ExisteCodigo= this.props.Productos.filter(
        (prod) => prod.Codigo === this.state.Codigo);
      if (ExisteCodigo.length === 0) {
        fetch(Globales.Url + "Catalogos/Productos/RegistrarProducto", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: Globales.token,
          },
          body: JSON.stringify({
            AplicaExistencia: this.state.Control,
            Codigo: this.state.Codigo,
            Costo:this.state.CostoOrg,
            Descripcion:String(this.state.Descripcion).toUpperCase(),
            Estado: 'Activo',
            Existencia: this.state.Existencia,
            IdIdioma: JSON.parse(this.state._idIdioma),
            IdProveedor: JSON.parse(this.state._idProveedor),
            NombreIdioma:'',
            NombreProveedor:'',
            Precio:this.state.PrecioOrg,
            Tipo: this.state._idTipoProducto,
            Unidades:{Unidad: this.state.ListaUnidades},
            _id: this.state._id,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              this.setState({
                colorNotificacion: "danger",
                mensaje: data.mensaje,
                DesactivarBoton: false,
                disabled: false,
                MostrarModal: false,
              });
              this.showNotification();
            } else {
              this.setState({
                colorNotificacion: "success",
                DesactivarBoton: false,
                disabled: false,
                MostrarModal: false,
              });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch((err) => console.error(err));
      } else {
        this.setState({
          colorNotificacion: "danger",
          mensaje: "El Código ya se encuentra registrado.",
          DesactivarBoton: false,
          MostrarModal: false,
          disabled: false,
        });
        this.showNotification();
      }
    } else {
      const ExisteCodigo= this.props.Productos.filter(
        (prod) => prod.Codigo === this.state.Codigo);

      if (ExisteCodigo.length === 0) {
        fetch(Globales.Url + "Catalogos/Productos/RegistrarProducto", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: Globales.token,
          },
          body: JSON.stringify({
            AplicaExistencia: this.state.Control,
            Codigo: this.state.Codigo,
            Costo:this.state.CostoOrg,
            Descripcion:String(this.state.Descripcion).toUpperCase(),
            Estado: 'Activo',
            Existencia: this.state.Existencia,
            IdIdioma: JSON.parse(this.state._idIdioma) || this.state._idIdioma,
            IdProveedor: JSON.parse(this.state._idProveedor) || this.state._idProveedor,
            NombreIdioma:'',
            NombreProveedor:'',
            Precio:this.state.PrecioOrg,
            Tipo: this.state._idTipoProducto,
            Unidades:{Unidad: this.state.ListaUnidades},
            _id: this.state.IdNormal,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              this.setState({
                colorNotificacion: "danger",
                mensaje: data.mensaje,
                DesactivarBoton: false,
                cargando: false,
                MostrarModal: false,
                disabled: false,
              });
              this.showNotification();
            } else {
              this.setState({
                colorNotificacion: "success",
                DesactivarBoton: false,
                disabled: false,
                MostrarModal: false,
                cargando: false,
              });
              this.closeModal(data.mensaje, this.state.colorNotificacion);
            }
          })
          .catch((err) => console.error(err));
      } else {
        this.setState({
          colorNotificacion: "danger",
          mensaje: "El Código ya se encuentra registrado.",
          DesactivarBoton: false,
          MostrarModal: false,
          disabled: false,
        });
        this.showNotification();
      }
    }
  }

  AgregarUnidad(){
    if(this.state.NombreUnidad=== ''){
      this.setState({
        ErrorUnidad:true
      })
      return
    }else{
      const Unidad = this.state.ListaUnidades;
      const Existe = Unidad.filter(sa=> String(sa.NombreUnidad).toUpperCase() === String(this.state.NombreUnidad).toUpperCase());
      if(Existe.length === 0){
        Unidad.push({
          Estado: "Activo",
          NombreUnidad:this.state.NombreUnidad, 
          Orden:Unidad.length,
        })
        this.setState({
          ListaUnidades:Unidad,
          NombreUnidad:''
        })
      }else{
        this.setState({
          ErrorUnidad:true,
          colorNotificacion:'danger',
          mensaje: 'La unidad, ya se encuentra registrada.',
        })
        this.showNotification();
      }
    }
  }

  closeModal(mensaje, colorNotificacion) {
      this.props.eventClick(mensaje, colorNotificacion);
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    let Cantidad = 0;
    if(name === 'Costo' || name === 'Precio' ){
      Cantidad = value.replace(/[^0-9\.\$]/g, "");
      Cantidad = Cantidad.indexOf("$") === -1? Cantidad : Cantidad.slice(1);
      if (Cantidad < 0) {
        this.setState({ [name]: 0, [name + 'Org']: 0});
      } else {
        this.setState({ [name]: Cantidad, [name + 'Org']: Cantidad, ErrorAdulto: false, ErrorAdolesente:false, ErrorNiños:false});
      }
    }
    else if(name === 'Control'){
      this.setState({ [name]: !this.state.Control});

    }
    else{
      this.setState({ [name]: value});
    }
    if(name === '_idTipoProducto' && value !== 'Libro'){
      this.setState({
        ListaUnidades:[],
        NombreUnidad:''
      })
    }
    this.setState({
      ErrorPrecio: false,
      ErrorCosto: false,
      ErrorProveedor: false,
      ErrorCodigo: false,
      ErrorDescripcion: false,
      ErrorTipo: false,
      ErrorIdioma: false,
      ErrorExistencia: false,
      ErrorUnidad: false,
    })
  }

  handleFocus = (event) => {
    const e = event.target;
    const { name } = event.target;
    this.setState({ [name]: this.state[name + 'Org'] }, () => e.select());
  };

  handleBlur = (event) => {
    const { name, value } = event.target;
    let Valor = value.indexOf("$") > -1?  value.slice(1): value.indexOf(",") > -1? value.replace(/(,)/gm,""):value;  
    if (isNaN(value)) {
      this.setState({ [name]: convertirPesos(0) });
    } else {
      this.setState({ [name]: convertirPesos(Valor) });
    }
  };

  EliminarUnidad(unidad){
    let index = this.state.ListaUnidades.indexOf(unidad);

    this.state.ListaUnidades.splice(index, 1);

    this.setState({ListaUnidades: this.state.ListaUnidades})
  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }

  render() {
    const { classes } = this.props;

    let agregar
    if (this.state.cargando == true) {
      agregar = (
        <Cargando show={this.state.MostrarModal} Cuerpo="Cargando" />
      );
    }

    let Unidad, Filtro;
    const style = {
      position: "absolute",
      top: "50%",
      left: "60%",
      transform: "translate(-50%, -50%)",
      width: 700,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    };
    if(this.state._idTipoProducto === 'Libro'){
      Filtro = <div>
      <GridItem xs={12} sm={6} md={6}>
                    <TextField
                      id="NombreUnidad"
                      style={{ width: "23vw" }}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Nombre de la unidad"
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.NombreUnidad,
                        name: "NombreUnidad",
                        id: "NombreUnidad",
                      error: this.state.ErrorUnidad,

                      }}
                    />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                    <Button
                      size="sm"
                      type="submit"
                      color="info"
                      round
                      className="pull-right"
                      onClick={() => this.AgregarUnidad()}
                    >
                      Agregar
                    </Button>
            </GridItem>
            </div>
      Unidad = (<div style={{width:'100%'}}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 className="pull-left">Lista de unidades</h4>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <div className="table-wrapper-scroll-y">
                  <table className="col-xs-12 col-sm-12 col-md-12">
                    <tbody>
                      {this.state.ListaUnidades.map((Unidad, index) => {
                        return (
                          <tr key={Unidad._id + "-" + index}>
                            <td>{Unidad.NombreUnidad}</td>
                            <td className="pull-right">
                              <Button
                                color="danger"
                                round
                                justIcon
                                size="sm"
                                onClick={() => this.EliminarUnidad(Unidad)}
                              >
                                <Close />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
            </div>
      )
    }
    return (
      <div>
        <Modal
          isOpen={this.props.showAgregar}
          className="static-modal"
          size="lg"
          toggle={this.cerrarModal}
          backdrop={true}
          // style={style}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    size="small"
                    variant="outlined"
                    // style={{ marginBottom: "27px" }}
                    // error= {this.state.ErrorIdioma}
                  >
                    <InputLabel>Idioma</InputLabel>
                    <Select
                      value={this.state._idIdioma}
                      size="small"
                      label="Idioma"
                      onChange={this.handleChange}
                      name="_idIdioma"
                      id="_idIdioma"
                      defaultValue={""}
                      style={{ marginBottom: "10px" }}
                      error={this.state.ErrorIdioma}
                      // success={!this.state.ErrorIdioma}
                    >
                      <MenuItem disabled>Seleccione un idioma</MenuItem>
                      {this.state.ListaIdiomas.map((idioma, Index) => (
                        <MenuItem
                          key={Index + idioma.Nombre}
                          value={JSON.stringify(idioma._id)}
                        >
                          {idioma.Nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    size="small"
                    variant="outlined"
                    // style={{ marginBottom: "27px" }}
                    // error= {this.state.ErrorTipo}
                  >
                    <InputLabel>Tipo de producto</InputLabel>
                    <Select
                      value={this.state._idTipoProducto}
                      onChange={this.handleChange}
                      name="_idTipoProducto"
                      size="small"
                      label="Tipo de producto"
                      id="_idTipoProducto"
                      defaultValue={""}
                      style={{ marginBottom: "10px" }}
                      error={this.state.ErrorTipo}
                      // success={!this.state.ErrorTipo}
                    >
                      <MenuItem disabled>Seleccione un tipo de producto</MenuItem>
                      {this.state.ListaTipoProductos.map((TP, Index) => (
                        <MenuItem key={Index + TP.Descripcion} value={TP._id}>
                          {TP.Descripcion}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={3} lg={3}>
                    <TextField
                      id="Código"
                      style={{ marginBottom: "10px" }}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Código"
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.Codigo,
                        name: "Codigo",
                        id: "Codigo",
                        error: this.state.ErrorCodigo,
                        success: !this.state.ErrorCodigo,
                      }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={9} lg={9}>
                    <TextField
                      id="Descripción"
                      style={{ display: "flex", marginBottom: "10px" }}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Descripción"
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.Descripcion,
                        name: "Descripcion",
                        id: "Descripcion",
                        error: this.state.ErrorDescripcion,
                        success: !this.state.ErrorDescripcion,
                      }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="Existencia"
                      style={{ display: "flex", marginBottom: "10px" }}
                      variant="outlined"
                      type="number"
                      size="small"
                      label="Existencia"
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        value: this.state.Existencia,
                        name: "Existencia",
                        id: "Existencia",
                        error: this.state.ErrorExistencia,
                        success: !this.state.ErrorExistencia,
                      }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControlLabel
                    label="Control inventario"
                    style={{ top: "5%", marginBottom: "10px" }}
                    control={
                      <Checkbox
                        id="Control"
                        name="Control"
                        checked={this.state.Control}
                        onChange={this.handleChange.bind(this)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="Precio"
                      style={{ display: "flex", marginBottom: "10px" }}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Precio"
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        onBlur: this.handleBlur.bind(this),
                        onFocus: this.handleFocus.bind(this),
                        value: this.state.Precio,
                        name: "Precio",
                        id: "Precio",
                        error: this.state.ErrorPrecio,
                        success: !this.state.ErrorPrecio,
                      }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      id="Costo"
                      style={{ display: "flex", marginBottom: "10px" }}
                      variant="outlined"
                      type="text"
                      size="small"
                      label="Costo"
                      InputProps={{
                        onChange: this.handleChange.bind(this),
                        onBlur: this.handleBlur.bind(this),
                        onFocus: this.handleFocus.bind(this),
                        value: this.state.Costo,
                        name: "Costo",
                        id: "Costo",
                        error: this.state.ErrorCosto,
                        success: !this.state.ErrorCosto,
                      }}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    size="small"
                    variant="outlined"
                    // style={{ marginBottom: "27px" }}
                    // error= {this.state.ErrorProveedor}
                  >
                    <InputLabel>Proveedor</InputLabel>
                    <Select
                      value={this.state._idProveedor}
                      size="small"
                      label="Proveedor"
                      onChange={this.handleChange}
                      style={{ marginBottom: "10px", display: "flex" }}
                      name="_idProveedor"
                      id="_idProveedor"
                      error={this.state.ErrorProveedor}
                    >
                      <MenuItem disabled>Seleccione un proveedor</MenuItem>
                      {this.state.ListaProveedores.map((Prove, Index) => (
                        <MenuItem
                          key={Index + Prove.Razonsocial}
                          value={JSON.stringify(Prove._id)}
                        >
                          {Prove.Razonsocial}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}/>
                <GridItem xs={12} sm={12} md={12} lg={12}/>
                <GridItem xs={12} sm={12} md={6} lg={6}/>
                <GridItem xs={12} sm={12} md={6} lg={6}/>
                <GridItem xs={12} sm={12} md={12} lg={12}/>
                {Filtro}
                {Unidad}
                <GridItem xs={12} sm={12} md={12}>
                  <Button
                    disabled={this.state.disabled}
                    size="sm"
                    color="info"
                    className="pull-right"
                    round
                    onClick={this.ValidarDatos.bind(this)}
                  >
                    <Save />
                    &nbsp;GUARDAR
                  </Button>
                  <Button
                    size="sm"
                    className="pull-right mr-2"
                    round
                    onClick={() => this.props.eventCancelar("esc")}
                  >
                    <Clear />
                    &nbsp;CANCELAR
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          {agregar}
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalProductos);
