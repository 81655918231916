import React, { Component } from "react";
import { Form, Modal } from "reactstrap";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Cargando from '../../../components/Generales/ModalCargando';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Globales from "utils/Globales";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Save, Clear, Search, Check, ArrowForward, ArrowForwardIos, DesktopAccessDisabledSharp } from "@material-ui/icons";
import { parsePhoneNumberFromString, parsePhoneNumber, format, formatNumber } from "libphonenumber-js";
import moment from 'moment';
import cookie from 'js-cookie'
import Autocomplete from '@material-ui/lab/Autocomplete';

class ModalDatosFiscales extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      MostrarModal: false,
      _id: '',
      ObjDatosFiscales: {},
      ListaNombres: [],
      ObjNombreFiscal: {},
      Nombre: "",
      RFC: "",
      Correo: "",
      Calle: "",
      NumCalle: "",
      Colonia: "",
      CodigoPostal: "",
      ClaveRegimen: "",
      ListaEstados: [],
      ListaMunicipio: [],
      ListaClavesRegimen: [],
      IdEstado: "",
      IdMunicipio: "",
      disabled: false,
      errorNombre: false,
      errorRFC: false,
      errorCorreo: false,
      errorCalle: false,
      errorColonia: false,
      errorListaEstados: false,
      errorListaMunicipio: false,
      colorNotificacion: "success",
      mensaje: "",
      br: false,
      DesactivarBoton: false,
      NombreGuardado: '',
      SubcategoriaHabilitada: false,
      CuerpoCargando: "Cargando",
      aviso: ""

    }
    this.handleChange = this.handleChange.bind(this);
    this.InputChange = this.InputChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick();
    }
  }

  async componentDidMount() {
    await this.ObtenerClaveRegimenFiscal()
    if (this.props.op == 1) {
      const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };
      // console.log(this.props.DatosFiscales)
      this.setState({
        ObjNombreFiscal: this.props.DatosFiscales,
        ClaveRegimen: this.props.DatosFiscales.ClaveRegimen ? this.props.DatosFiscales.ClaveRegimen : "",
        Nombre: this.props.DatosFiscales.RazonSocial,
        RFC: this.props.DatosFiscales.RFC,
        Correo: this.props.DatosFiscales.Correo,
        Calle: this.props.DatosFiscales.Calle,
        NumCalle: this.props.DatosFiscales.Numero,
        Colonia: this.props.DatosFiscales.Colonia,
        CodigoPostal: this.props.DatosFiscales.CodigoPostal,
      })

      if (JSON.stringify(this.props.DatosFiscales._idEstado) !== JSON.stringify(idVacio)) {
        this.ListaGeneralEstados(this.props.DatosFiscales._idEstado);

        if (JSON.stringify(this.props.DatosFiscales._idciudad) !== JSON.stringify(idVacio)) {
          this.ListaMunicipiosEstado(this.props.DatosFiscales._idEstado, this.props.DatosFiscales._idciudad)
        }
        else {
          this.ListaMunicipiosEstado(this.props.DatosFiscales._idEstado, 0)
        }
      }
      else {
        this.ListaGeneralEstados(0);
        this.ListaMunicipiosEstado("", 0)
      }

      // if(JSON.stringify(this.props.DatosFiscales._idciudad) !== JSON.stringify(idVacio)){
      //   this.ListaMunicipiosEstado(this.props.DatosFiscales._idEstado, this.props.DatosFiscales._idciudad)
      // }
      // else{
      //   this.ListaMunicipiosEstado("", 0)
      // }


    }
    else {
      this.ListaGeneralEstados(0);
    }
  }

  async ObtenerClaveRegimenFiscal() {
    this.setState({ cargando: true });
    await fetch(Globales.Url + 'Facturacion/Facturas/ObtenerClaveRegimenFiscal', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({})
    }
    ).then(res => res.json())
      .then(data => {
        // console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ cargando: false });
          // this.showNotification();
        } else { //Filtrado exitoso
          this.setState({ ListaClavesRegimen: data.data, cargando: false });
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  async ListaGeneralEstados(idEstado) {
    this.setState({ ListaEstados: [], cargando: true });
    await fetch(Globales.Url + 'Alumnos/Alumnos/ListaGeneralEstados', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify()
    }
    ).then(res => res.json())
      .then(data => {
        // console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ cargando: false });
          // this.showNotification();
        } else { //Filtrado exitoso

          if (!Array.isArray(data.ListaEstados)) {
            data.ListaEstados = [data.ListaEstados]
          }

          if (idEstado !== 0) {
            data.ListaEstados.map(estado => {
              if (JSON.stringify(idEstado) == JSON.stringify(estado._id)) {
                this.setState({ IdEstado: estado._id, cargando: false });
              }
            })
          }

          this.setState({ ListaEstados: data.ListaEstados, cargando: false });
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  async ListaMunicipiosEstado(idEstado, idMunicipio) {
    this.setState({ cargando: true });
    await fetch(Globales.Url + 'Alumnos/Alumnos/ListaMunicipiosEstado', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _idEstado: idEstado })
    }
    ).then(res => res.json())
      .then(data => {
        // console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ cargando: false });
          // this.showNotification();
        } else { //Filtrado exitoso

          if (!Array.isArray(data.ListaMunicipio)) {
            data.ListaMunicipio = [data.ListaMunicipio]
          }

          if (idMunicipio !== 0) {
            data.ListaMunicipio.map(ciudad => {
              if (JSON.stringify(ciudad._id) == JSON.stringify(idMunicipio)) {
                //console.log(true)
                this.setState({ IdMunicipio: ciudad._id, Activo: 1, cargando: false });
              }
            })
          }

          this.setState({ ListaMunicipio: data.ListaMunicipio, cargando: false });
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  async ListaFiltradoDatosFiscales(texto) {
    // this.setState({cargando: true});
    await fetch(Globales.Url + 'Alumnos/Alumnos/ListaFiltradoDatosFiscales', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ nombre: texto })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ cargando: false });
          //this.showNotification();
        } else { //Filtrado exitoso
          if (data.ListaNombres.length > 1) {
            this.setState({ ListaNombres: data.ListaNombres });
          }
          else {
            let aux = [];
            aux.push(data.ListaNombres);
            this.setState({ ListaNombres: aux });
          }
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  CrearObjDatosFiscales() {
    let valido = true
    const IsEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const idVacio = { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" };

    // if(JSON.stringify(this.state.IdMunicipio) == JSON.stringify(idVacio)){
    //   valido = false;
    //   this.setState({errorListaMunicipio: true, mensaje: "Seleccione la ciudad"})
    //   this.showNotification();
    // }
    // else{
    //   this.setState({errorListaMunicipio:false})
    // }

    // if(JSON.stringify(this.state.IdEstado) == JSON.stringify(idVacio)){
    //   valido = false;
    //   this.setState({errorListaEstados: true, mensaje: "Seleccione el estado", colorNotificacion: "danger"})
    //   this.showNotification();
    // }
    // else{
    //   this.setState({errorListaEstados:false})
    // }

    // if(this.state.Colonia == "" ){
    //   valido = false;
    //   this.setState({errorColonia: true, mensaje: "Proporcione la colonia", colorNotificacion: "danger"})
    //   this.showNotification();
    // }
    // else{
    //   this.setState({errorColonia:false})
    // }

    // if(this.state.Calle == "" ){
    //   valido = false;
    //   this.setState({errorCalle: true, mensaje: "Proporcione la calle", colorNotificacion: "danger"})
    //   this.showNotification();
    // }
    // else{
    //   this.setState({errorCalle:false})
    // }

    if (this.state.Correo !== "") {
      if (IsEmail.test(this.state.Correo) === false) {
        valido = false;
        this.setState({ errorCorreo: true, mensaje: "Proporcione un correo valido", colorNotificacion: "danger" })
        this.showNotification();
      }
      else {
        this.setState({ errorCorreo: false })
      }
    }

    if (this.state.RFC == "") {
      valido = false;
      this.setState({ errorRFC: true, mensaje: "Proporcione RFC", colorNotificacion: "danger" })
      this.showNotification();
    }
    else {
      this.setState({ errorRFC: false })
    }

    if (this.state.Nombre == "") {
      this.setState({ errorNombre: true, mensaje: "Proporcione la razón social", colorNotificacion: "danger" })
      this.showNotification();
    }
    else {
      this.setState({ errorNombre: false })
    }

    if (this.state.ClaveRegimen == "") {
      valido = false;
      this.setState({ errorRegimen: true, mensaje: "Proporcione regimen fiscal", colorNotificacion: "danger" })
      this.showNotification();
    }
    else {
      this.setState({ errorRegimen: false })
    }
    if (this.state.CodigoPostal == "") {
      valido = false;
      this.setState({ errorCodigoPostal: true, mensaje: "Proporcione codigo postal", colorNotificacion: "danger" })
      this.showNotification();
    }
    else {
      this.setState({ errorCodigoPostal: false })
    }

    if (valido) {
      this.setState({
        ObjDatosFiscales: {
          Calle: this.state.Calle,
          ClaveRegimen: this.state.ClaveRegimen.toString(),
          CodigoPostal: this.state.CodigoPostal,
          Colonia: this.state.Colonia,
          Correo: this.state.Correo,
          Descuento: 0,
          Numero: this.state.NumCalle,
          RFC: this.state.RFC,
          RazonSocial: this.state.Nombre,
          _id: Object.keys(this.state.ObjNombreFiscal).length !== 0 ? this.state.ObjNombreFiscal._id : idVacio,
          _idEstado: this.state.IdEstado !== "" ? this.state.IdEstado : idVacio,
          _idciudad: this.state.IdMunicipio !== "" ? this.state.IdMunicipio : idVacio
        }
      }, () => this.RegistrarDatosFiscales());
    }
  }

  async RegistrarDatosFiscales() {
    // console.log(this.state.ObjDatosFiscales);
    this.setState({ cargando: true });
    await fetch(Globales.Url + 'Alumnos/Alumnos/RegistrarDatosFiscales', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ DatosFiscales: this.state.ObjDatosFiscales })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log(data);
        if (data.error) { //Error en la respuesta
          this.setState({ mensaje: data.mensaje, colorNotificacion: "danger", Activo: 1, cargando: false });
          this.showNotification();
        } else { //Filtrado exitoso
          this.setState({ cargando: false })
          this.RegresarMensaje(data.mensaje, data.idDatosFiscales, this.state.ObjDatosFiscales);
        }
      })
      .catch(err => this.setState({ mensaje: "Ocurrió un error inesperado, por favor inténtelo de nuevo", colorNotificacion: "danger", br: true, CuerpoCargando: "Error", cargando: true }));
  }

  // Regresar datos hacia afuera del modal ***********************************************************************************************************
  RegresarMensaje(mensaje, idDatos, DatosFiscales) {
    this.setState({ op1: 1 });
    this.props.parentCallback(mensaje, idDatos, DatosFiscales);
    this.props.eventClick("esc");
  }

  closeModal() {
    this.props.eventClick("esc");
  }

  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      4000
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    //console.log(e.target)
    if (name == "IdEstado") {
      this.ListaMunicipiosEstado(value, 0);
      this.setState({ [name]: value, ListaMunicipio: [], IdMunicipio: { _increment: "0", _machine: "0", _pid: "0", _timestamp: "0" } });
    }

    this.setState({ [name]: value });
  }

  handleChangeAutocomplete(e, newValue) {
    if (newValue !== undefined && newValue !== null) {
      let Estado;

      this.state.ListaEstados.map(estado => {
        if (JSON.stringify(estado._id) == JSON.stringify(newValue._idEstado)) {
          Estado = estado._id;
        }
      })

      this.ListaMunicipiosEstado(newValue._idEstado, newValue._idciudad);

      this.setState({
        ObjNombreFiscal: newValue,
        RFC: newValue.RFC !== undefined && newValue.RFC !== null ? newValue.RFC : "",
        ClaveRegimen: newValue.ClaveRegimen ? newValue.ClaveRegimen : "",
        Correo: newValue.Correo !== undefined && newValue.Correo !== null ? newValue.Correo : "",
        Calle: newValue.Calle !== undefined && newValue.Calle !== null ? newValue.Calle : "",
        NumCalle: newValue.Numero !== undefined && newValue.Numero !== null ? newValue.Numero : "",
        Colonia: newValue.Colonia !== undefined && newValue.Colonia !== null ? newValue.Colonia : "",
        CodigoPostal: newValue.CodigoPostal !== undefined && newValue.CodigoPostal !== null ? newValue.CodigoPostal : "",
        IdEstado: Estado,
        IdMunicipio: Object.keys(this.state.IdMunicipio).length !== 0 ? this.state.IdMunicipio : ""
      })
    }
  }

  InputChange(e, newInputValue) {
    //const { name, value2 } = e.target;
    this.ListaFiltradoDatosFiscales(newInputValue);
    this.setState({ Nombre: newInputValue });

  }

  cerrarModal = () => {
    this.props.eventClick("esc")
  }

  render() {
    const { classes } = this.props;
    //console.log(this.state._id)
    //console.log(this.state.ListaNombres)
    //console.log(this.state.ObjNombreFiscal)

    let agregar;
    if (this.state.cargando) {
      agregar = (
        <Cargando
          Cuerpo={this.state.CuerpoCargando}
          show={this.state.cargando}
        />
      )
    }

    return (
      <div>
        <Modal
          isOpen={this.props.showDatosFiscales}
          className="static-modal"
          size="md"
          toggle={this.cerrarModal}
          backdrop={true}
        >
          <Card>
            <CardHeader color="info">{this.props.Titulo}</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Autocomplete
                    freeSolo
                    id="Nombre"
                    noOptionsText={'No hay opciones'}
                    style={{ width: "100%" }}
                    disableClearable
                    value={this.state.ObjNombreFiscal}
                    onChange={this.handleChangeAutocomplete.bind(this)}
                    onInputChange={this.InputChange.bind(this)}
                    options={this.state.ListaNombres}
                    getOptionLabel={(option) => option.RazonSocial}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Nombre o razón social"
                        margin="normal"
                        size="small"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                          name: "Nombre",
                          id: "Nombre",
                          error: this.state.errorNombre,
                          success: !this.state.errorNombre
                        }}
                      />
                    )}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <TextField
                    id="RFC"
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="RFC"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.RFC,
                      name: "RFC",
                      id: "RFC",
                      error: this.state.errorRFC,
                      success: !this.state.errorRFC
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <TextField
                    id="Correo"
                    fullWidth
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Correo"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Correo,
                      name: "Correo",
                      id: "Correo",
                      error: this.state.errorCorreo,
                      success: !this.state.errorCorreo
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={8} md={8}>
                  <TextField
                    id="Calle"
                    fullWidth
                    style={{ marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Calle"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Calle,
                      name: "Calle",
                      id: "Calle",
                      error: this.state.errorCalle,
                      success: !this.state.errorCalle
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <TextField
                    id="NumCalle"
                    fullWidth
                    style={{ marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Número"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.NumCalle,
                      name: "NumCalle",
                      id: "NumCalle",
                      //error: this.state.errorFechainicio,
                      //success: !this.state.errorFechainicio
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={8} md={8}>
                  <TextField
                    id="Colonia"
                    fullWidth
                    style={{ marginTop: "10px", marginRight: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="Colonia"
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.Colonia,
                      name: "Colonia",
                      id: "Colonia",
                      error: this.state.errorColonia,
                      success: !this.state.errorColonia
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <TextField
                    id="CodigoPostal"
                    fullWidth
                    style={{ marginTop: "10px" }}
                    variant="outlined"
                    type="text"
                    size="small"
                    label="C.P."
                    InputProps={{
                      onChange: this.handleChange.bind(this),
                      value: this.state.CodigoPostal,
                      name: "CodigoPostal",
                      id: "CodigoPostal",
                      error: this.state.errorCodigoPostal,
                      //success: !this.state.errorFechainicio
                    }}
                  />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{ marginTop: "10px" }} id="Estado">Estado</InputLabel>
                    <Select
                      labelId="Estado"
                      value={this.state.IdEstado}
                      onChange={this.handleChange.bind(this)}
                      name="IdEstado"
                      label="Estado"
                      style={{ marginTop: "10px" }}
                      error={this.state.errorListaEstados}
                      success={!this.state.errorListaEstados}
                    >
                      <MenuItem disabled>Seleccione un estado</MenuItem>
                      {this.state.ListaEstados.map(estado =>
                        <MenuItem value={estado._id}>{(estado.NombreEstado)}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{ marginTop: "10px" }} id="Ciudad">Ciudad</InputLabel>
                    <Select
                      labelId="Ciudad"
                      value={this.state.IdMunicipio}
                      onChange={this.handleChange.bind(this)}
                      name="IdMunicipio"
                      label="Ciudad"
                      style={{ marginTop: "10px" }}
                      error={this.state.errorListaMunicipio}
                      success={!this.state.errorListaMunicipio}
                    >
                      <MenuItem disabled>Seleccione un municipio</MenuItem>
                      {this.state.ListaMunicipio.map(Ciudad =>
                        <MenuItem value={Ciudad._id}>{(Ciudad.NombreMunicipio)}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <FormControl fullWidth className={classes.formControl} size="small" variant="outlined">
                    <InputLabel style={{ marginTop: "10px" }} id="regimen">Regimen fiscal</InputLabel>
                    <Select
                      labelId="regimen"
                      value={this.state.ClaveRegimen}
                      onChange={this.handleChange.bind(this)}
                      name="ClaveRegimen"
                      label="Regimen fiscal"
                      style={{ marginTop: "10px" }}
                      error={this.state.errorRegimen}
                      success={!this.state.errorRegimen}
                    >
                      <MenuItem disabled>Seleccione un estado</MenuItem>
                      {this.state.ListaClavesRegimen.map(estado =>
                        <MenuItem value={estado.ClaveRegimen}>{(estado.Descripcion)}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Button color="info" tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.CrearObjDatosFiscales()}><Save />&nbsp;Guardar</Button>
                  <Button tabIndex="1" size='sm' className='pull-right ml-2 mt-3' round onClick={() => this.props.eventClick("esc")}><Clear />&nbsp;Cancelar</Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </Modal>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
          {agregar}
        </div>
      </div>
    );
  }
}

export default withStyles(basicsStyle)(ModalDatosFiscales);